// src/components/FilterBar.jsx

import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import '../styles/FilterBar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

// Define always-visible categories
const alwaysCategories = [
  { name: 'Recommended', emphasized: true },
  { name: 'Anime', premium1: true },
  { name: 'Picture Waifus', premium: true },
  { name: 'Furry', premium1: true },
  { name: 'Romance', premium1: true },
  { name: 'Anime Game', premium1: true },
  { name: 'Special', premium: true },
  { name: 'Male', premium1: true },
  { name: 'Saviorfagging', premium1: true },
  { name: 'Depressed', premium1: true },
  { name: 'Yandere', premium1: true },
  { name: 'Dominant', premium1: true },
  { name: 'Private Video Waifus', premium: true },
  { name: 'Giantess', premium1: true },
  { name: 'Femboy', premium1: true },
  { name: 'Cute', premium1: true },
  { name: 'Slave', premium1: true },
  { name: 'Virgin', premium1: true },
  { name: 'Sadistic', premium1: true },
  { name: 'Realistic', premium1: true },
  { name: 'Dark Fantasy', premium1: true },
  { name: 'VTuber', premium1: true },
  { name: 'Girlfriend', premium1: true },
  { name: 'Bully', premium1: true },
  { name: 'Scenario', premium1: true },
  { name: 'Action', premium1: true },
  { name: 'Love', premium1: true },
  { name: 'Multiple Characters', premium1: true },
];

// Define categories visible only to logged-in users (excluding 'Favorites')
const loggedInCategories = [
  //{ name: 'Preferences', emphasized: true },
  // 'Favorites' is separated and handled separately
];

// Define categories to be always displayed at the bottom
const bottomCategories = [
  { name: 'Favorites', emphasized1: true },
];

const FilterBar = ({ handleSelectCategory, selectedCategory }) => {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  // Combine main categories based on authentication state
  const mainCategories = useMemo(() => {
    if (currentUser && !currentUser.isAnonymous) {
      // Prepend loggedInCategories (excluding 'Favorites') to alwaysCategories
      return [...loggedInCategories, ...alwaysCategories];
    }
    return alwaysCategories;
  }, [currentUser]);

  // Determine if the user is logged in and not anonymous to show bottom categories
  const showBottomCategories = useMemo(() => {
    return currentUser && !currentUser.isAnonymous;
  }, [currentUser]);

  // Define the number of categories to show when not expanded
  const initialCategoriesCount = 3;

  // Function to get the expanded state from URL
  const getExpandedFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('expanded') === 'true';
  };

  const [isExpanded, setIsExpanded] = useState(getExpandedFromURL());

  // Update URL when isExpanded changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (isExpanded) {
      searchParams.set('expanded', 'true');
    } else {
      searchParams.delete('expanded');
    }
    navigate({ search: searchParams.toString() }, { replace: true });
  }, [isExpanded, navigate, location.search]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Function to render a category button
  const renderCategoryButton = (category) => {
    // Determine if the current category is 'Preferences'
    const isPreferences = category.name.toLowerCase() === 'preferences';

    return (
      <button
        key={category.name}
        className={`
          ${selectedCategory.toLowerCase() === category.name.toLowerCase() ? 'active' : ''} 
          filterButton 
          ${category.premium ? 'premium' : ''}
          ${category.premium1 ? 'premium1' : ''} 
          ${category.emphasized ? 'emphasized' : ''} 
          ${category.emphasized1 ? 'emphasized1' : ''} 
          ${category.emphasized2 ? 'emphasized2' : ''} 
          ${category.emphasized3 ? 'emphasized3' : ''} 
          ${isPreferences ? 'preferences-button' : ''}
        `}
        onClick={() => handleSelectCategory(category.name.toLowerCase())}
        aria-pressed={selectedCategory.toLowerCase() === category.name.toLowerCase()}
      >
        {category.name}
        {category.premium && <span className="premium-tag">Premium</span>}
      </button>
    );
  };

  // Determine which main categories to display based on expansion state
  const mainCategoriesToRender = mainCategories.slice(
    0,
    isExpanded ? mainCategories.length : initialCategoriesCount
  );

  return (
    <div className={`filterBtnContainer ${isExpanded ? 'expanded' : ''}`}>
      {/* Render main categories */}
      {mainCategoriesToRender.map(renderCategoryButton)}

      {/* Expand/Collapse button for main categories */}
      {mainCategories.length > initialCategoriesCount && (
        <button className="expandButton" onClick={toggleExpand}>
          {isExpanded ? 'Collapse' : 'Expand All'}
          <FontAwesomeIcon
            className="expandIcon"
            style={{ margin: '10px' }}
            icon={isExpanded ? faChevronUp : faChevronDown}
          />
        </button>
      )}

      {/* Render bottom categories (e.g., Favorites) */}
      {showBottomCategories && bottomCategories.map(renderCategoryButton)}
    </div>
  );
};

FilterBar.propTypes = {
  handleSelectCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
};

export default FilterBar;
