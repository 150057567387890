import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { AuthContext } from "../../context/AuthContext";
import { BACKEND_URL } from "../../constants";
import { db, logEvent } from "../../firebase";
import "../../styles/Checkout.scss";
import { message } from "antd";
import * as Sentry from "@sentry/react";
import {
    collection,
    addDoc,
    doc,
    getDoc,
    updateDoc,
} from "firebase/firestore";

const Checkout = () => {
    const location = useLocation();
    const [showFeedback, setShowFeedback] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [discountedPrice, setDiscountedPrice] = useState(null);
    const { productName, productPrice, type, productID } = useParams();
    const { currentUser, userData } = useContext(AuthContext);
    const [price, setPrice] = useState(parseFloat(productPrice)); // Convert string to number

    const [loading, setLoading] = useState(false);
    const UID = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser && currentUser.uid !== undefined) {
            console.log('Current user:', currentUser);
            UID.current = currentUser.uid;
        }
    }, [currentUser]);


    const logPayPalButtonClick = () => {
        logEvent('paypal_click_' + productName, {
            product_name: productName,
            product_id: productID,
        });
    
        window.trackMetaConversion('AddPaymentInfo', price.toFixed(2), {
            content_ids: [productID],
            content_name: productName,
            content_type: 'product',
            currency: 'USD',
        });
    };

    const createOrder = useCallback((data, actions) => {
        console.log("currentprice", price);
        return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
                {
                    description: productName,
                    amount: {
                        value: price.toFixed(2), // Ensure 2 decimal places
                        currency_code: "USD",
                    },
                    custom_id: UID.current, // Pass UID in custom_id
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING",
            },
        });
    }, [price]);

    const createSubscription = (data, actions) => {
        return actions.subscription.create({
            plan_id: productID,
            custom_id: UID.current // Pass UID in custom_id
        });
    };

    const savePurchaseInfo = async (details, type) => {
        try {
            const dashboardCollection = collection(db, "dashboard");
            const userRef = doc(db, "users", UID.current);
            const userDoc = await getDoc(userRef);
            const userData = userDoc.data();

            const purchaseInfo = {
                userId: UID.current,
                productName: productName,
                productPrice: discountedPrice !== null ? discountedPrice : productPrice,
                transactionId: details ? details.id : "",
                purchaseDate: new Date(),
                type: type,
                lastChatID: userData.lastChatID || null,
                messages: []
            };

            if (userData.lastChatID) {
                const chatRef = doc(db, "chats", userData.lastChatID);
                const chatDoc = await getDoc(chatRef);
                if (chatDoc.exists()) {
                    purchaseInfo.messages = chatDoc.data().messages || [];
                }
            }

            await addDoc(dashboardCollection, purchaseInfo);
            console.log("Purchase info saved to dashboard collection");
        } catch (error) {
            console.error("Error saving purchase info to dashboard:", error);
            Sentry.captureException(error);
        }
    };

    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            // Set payment processing in localStorage
            localStorage.setItem("paymentProcessing", "true");
            savePurchaseInfo(details, 'one-time');
            logEvent('purchase', {
                value: discountedPrice !== null ? discountedPrice : productPrice,
                currency: 'USD',
                transaction_id: details.id,
                product_name: productName,
                product_id: productID,
                userId: UID.current,
                type: type,
            });
            window.trackTwitterConversion('purchaseGems', discountedPrice !== null ? discountedPrice : productPrice);
            message.success("Thanks for purchasing!");

            // Tapfiliate conversion tracking
            if (window.tap) {
                const amt = discountedPrice !== null ? discountedPrice : productPrice;
                const tx = details.id;
                window.tap('conversion', tx, amt);
            }

            navigate("/shop");
        });
    };

    const onApproveSubscription = async (data, actions) => {
        return actions.subscription.get().then(async function (details) {
            if (userData.subscription !== 'None') {
                try {
                    await cancelSubscription();
                } catch (error) {
                    console.error("Error cancelling subscription: ", error);
                    return;
                }
            }
            try {
                const userRef = doc(db, "users", UID.current);
                await updateDoc(userRef, {
                    subscription: "Ultimate Plan",
                    subscription_id: details.id
                });
                await savePurchaseInfo(details, 'subscription');
                console.log("User subscription updated to Ultimate Plan");
            } catch (error) {
                console.error("Error updating user subscription: ", error);
                Sentry.captureException(error);
            }
            logEvent('purchase', {
                value: productPrice,
                currency: 'USD',
                transaction_id: details.id,
                product_name: productName,
                product_id: productID,
                userId: UID.current,
                type: type,
            });
            window.trackTwitterConversion('purchaseSubscription', productPrice);
            // Set payment processing in localStorage
            localStorage.setItem("paymentProcessing", "true");
            message.success("Thank you for your subscription! It may take up to 2 minutes for the subscription to be activated");

            // Tapfiliate conversion tracking for subscription
            if (window.tap) {
                const amt = productPrice;
                const tx = details.id;
                window.tap('conversion', tx, amt);
            }

            navigate("/shop");
        });
    };

    const onError = (err) => {
        console.log(err);
        let errMessage = "An error occurred with your payment.";
        if (err.message.includes('insufficient funds')) {
            errMessage = "You have insufficient funds for this transaction.";
        }
        message.error(errMessage);
        Sentry.captureException(err);
        logEvent('checkout_error', {
            error_message: err.message,
            error_name: err.name,
            product_name: productName,
            product_id: productID,
        });
    };

    const applyCoupon = () => {
        if (!couponCode.trim()) {
            message.error("Please enter a coupon code");
            return;
        }
    
        // Original SECRET100 code
        if (couponCode === "SECRET100") {
            const newPrice = 0.01; // Minimum price to avoid PayPal errors
            setPrice(newPrice);
            setDiscountedPrice(newPrice);
            message.success("Maximum discount applied! Price is now $0.01");
        } 
        // New 90% off code
        else if (couponCode === "SUPER95") {
            const newPrice = price * 0.05; // 90% off
            setPrice(newPrice);
            setDiscountedPrice(newPrice);
            message.success("90% discount applied!");
        }
        else {
            setPrice(parseFloat(productPrice)); // Reset to original price
            setDiscountedPrice(null);
            message.error("Invalid coupon code");
        }
    };

    const subscribeWithPayabl = async () => {
        setLoading(true);
        let obj = {
            email: currentUser?.email,
            amount: productPrice,
            plan: productName,
            uid: currentUser?.uid,
        };
        let source = userData?.FormData?.source;
        let subid = userData?.FormData?.subid;
        if (source && subid && source !== "none" && subid !== "none") {
            obj['source'] = source;
            obj['subid'] = subid;
        }
        const res = await fetch(`${BACKEND_URL}/api/pay-with-payabl-sub`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        });

        let data = await res?.json();
        if (data?.data) {
            setLoading(false);
            window.open(data?.data);
        }
    };

    const payWithPayabl = async () => {
        setLoading(true);
        let obj = {
            email: currentUser?.email,
            amount: discountedPrice !== null ? discountedPrice : productPrice,
            gems: productName,
            uid: currentUser?.uid,
        };
        let source = userData?.FormData?.source;
        let subid = userData?.FormData?.subid;
        if (source && subid && source !== "none" && subid !== "none") {
            obj['source'] = source;
            obj['subid'] = subid;
        }
        const res = await fetch(`${BACKEND_URL}/api/pay-with-payabl`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        });

        let data = await res?.json();
        if (data?.data) {
            setLoading(false);
            console.log('Payment data:', data?.data);
            window.open(data?.data);
        }
    };

    const cancelSubscription = async () => {
        if (currentUser.isAnonymous) {
            throw new Error("You must be logged in to cancel your subscription");
        }
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        const userData = userDoc.data();

        if (!userData || userData.subscription === "None") {
            throw new Error("You don't have an active subscription");
        }

        const data = {
            subscription_id: userData.subscription_id,
            uid: currentUser.uid
        };

        const res = await fetch(`${BACKEND_URL}/api/cancelsubscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!res.ok) {
            const error = await res.text();
            throw new Error(error);
        }
        console.log("Subscription cancelled response:", res);
    };

    const FeedbackForm = () => {
        const [feedback, setFeedback] = useState("");

        const submitFeedback = async () => {
            if (feedback.trim()) {
                const feedbackCollection = collection(db, "feedbacks");
                await addDoc(feedbackCollection, {
                    text: feedback,
                    timestamp: new Date(),
                    user_id: UID.current
                });
                message.success("Feedback submitted. Thank you!");
                setFeedback("");
            } else {
                message.error("Please enter your feedback before submitting.");
            }
        };

        return (
            <div className="feedbackForm">
                <textarea
                    placeholder="Share your feedback with us..."
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                ></textarea>
                <button onClick={submitFeedback} className="button">Submit Feedback</button>
            </div>
        );
    };

    const renderLoading = () => (
        <div className="loader-container">
            <div className="spinner"></div>
        </div>
    );

    return (
        loading ? renderLoading() : (
            <div className="mainWrapper">
                <PayPalScriptProvider options={{
                    "client-id": "ATSozBY8MeQAaCrpz92R5wSOBfS2Jps2yBYwK3BRtbEA4d-SYvmTdO2Qt9mNYzIKqn6nQgI4rdnD5jUq",
                    vault: true,
                    intent: type === "subscription" ? "subscription" : "capture"
                }}>
                    <div className="checkoutWrapper">
                        <div className="buy-anonymously-section">
                            <h2><i className="fas fa-user-secret"></i> Buy Anonymously</h2>
                            <p>Enjoy our services without revealing your identity. Your privacy matters to us. Charges will also appear anonymously on your billing statement.</p>
                        </div>
                        <div className="desc">
                            <div className="title">{type === "subscription" ? `Subscribe to ${productName}` : `Buy ${productName}`}</div>
                            <div className="price">${price.toFixed(2)}</div>
                        </div>
                        <div className="coupon-section">
                            <div className="coupon-input-wrapper">
                                <i className="fas fa-tag coupon-icon"></i>
                                <input
                                    type="text"
                                    placeholder="Enter coupon code"
                                    value={couponCode}
                                    onChange={(e) => setCouponCode(e.target.value)}
                                />
                                <i className="fas fa-arrow-right apply-icon" onClick={applyCoupon}></i>
                            </div>
                        </div>
                        <div className="wrapper">
                            <PayPalButtons
                                key={price}
                                onClick={logPayPalButtonClick}
                                style={{ layout: "vertical" }}
                                createOrder={type !== "subscription" ? createOrder : undefined}
                                createSubscription={type === "subscription" ? createSubscription : undefined}
                                onApprove={type === "subscription" ? onApproveSubscription : onApprove}
                                onError={onError}
                            />
                        </div>
                        { /*{type === "subscription" && (
                            <div className="wrapper">
                                <button className="payWithCreditCard" onClick={subscribeWithPayabl}>
                                    <i className="fas fa-credit-card"></i> Subscribe with Credit Card
                                </button>
                            </div>
                        )} */}
                        <button className="showFeedback" onClick={() => setShowFeedback(!showFeedback)}>
                            {showFeedback ? "Hide Feedback" : "Provide Feedback"}
                        </button>
                        {showFeedback && <FeedbackForm />}
                    </div>
                </PayPalScriptProvider>
            </div>
        )
    );
};

export default Checkout;
