import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/Diary.scss";
import { AuthContext } from '../../../context/AuthContext';
import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { handleAIResponse } from '../../../AIConnector';
import { v4 as uuidv4 } from 'uuid';
import InfoButton from '../../Common/InfoButton';
import { ChevronLeft, Calendar, ThumbsUp, ThumbsDown } from 'lucide-react';
import "../../../styles/newDiary.scss";

function Diary({isOpen, handleClose, characterName, chatData}) {
  const [selectedEntry, setSelectedEntry] = useState(null);
  const {currentUser, userData} = useContext(AuthContext);
  const [diaryEntries, setDiaryEntries] = useState({});
  const entryCreating = useRef(false);
  const cancelToken = useRef(false);
  const [likedEntries, setLikedEntries] = useState({});
  const [dislikedEntries, setDislikedEntries] = useState({});



  useEffect(() => {
    // Add class to body when diary is open to prevent scrolling
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      // Hide the chat buttons when diary is open
      const backBtn = document.querySelector('.back');
      const burgerMenu = document.querySelector('.burger-menu');
      if (backBtn) backBtn.style.display = 'none';
      if (burgerMenu) burgerMenu.style.display = 'none';
    } else {
      document.body.style.overflow = 'unset';
      // Show the chat buttons when diary is closed
      const backBtn = document.querySelector('.back');
      const burgerMenu = document.querySelector('.burger-menu');
      if (backBtn) backBtn.style.display = 'block';
      if (burgerMenu) burgerMenu.style.display = 'block';
    }

    // Cleanup function
    return () => {
      document.body.style.overflow = 'unset';
      const backBtn = document.querySelector('.back');
      const burgerMenu = document.querySelector('.burger-menu');
      if (backBtn) backBtn.style.display = 'block';
      if (burgerMenu) burgerMenu.style.display = 'block';
    };
  }, [isOpen]);


  const selectEntry = (entry) => {
    setSelectedEntry(entry);
  };

  const closeEntry = () => {
    setSelectedEntry(null);
  };

  const getChatSinceDate = async (sinceDate) => {
    const messagesSinceDate = chatData.messages.filter(msg => {
      if (!msg.date) {
        console.error('Message missing timestamp:', msg);
        return false;
      }

      const msgDate = msg.date.toDate();
      return msgDate > sinceDate;
    });
    console.log(messagesSinceDate)
    return messagesSinceDate;
  };

  const containsNumbers = (text) => {
    return /\d/.test(text);
  };
  const getAllChats = async () => {
    if (chatData) {
      if(chatData && chatData.messages) {
        const allMessages = chatData.messages;
        console.log(allMessages)
        return allMessages;
      } else {
        console.log('No messages in the chat data');
        return [];
      }
    } else {
      console.log('Chat doc does not exist');
      return [];
    }
  };
  
  const createDiaryEntry = async (currentEntries) => {
    if (entryCreating.current) {
      cancelToken.current = true;
      console.log("Entry creation stopped");
      return;
    }
  
    console.log("Entry creation started");
    entryCreating.current = true;
    cancelToken.current = false;
  
    currentEntries[characterName] = currentEntries[characterName] || [];
  
    let messagesToInclude = currentEntries[characterName].length > 0 
      ? await getChatSinceDate(getLatestEntryDate(currentEntries[characterName])) 
      : await getAllChats();
  
    if (cancelToken.current) return;
  
    console.log("diary messages: ", messagesToInclude)
    if (messagesToInclude.length > 0) {
      const sortedMessages = sortMessagesByDate(messagesToInclude);
      const diaryEntryDate = getDiaryEntryDate(sortedMessages);
      const existingEntry = findEntryByDate(currentEntries[characterName], diaryEntryDate);
  
      if (!existingEntry) {
        const prompt = createPrompt(sortedMessages, currentEntries[characterName].length, characterName);
        const diaryEntryContent = await handleAIResponse(prompt);
  
        await createNewEntry(currentEntries, diaryEntryDate, diaryEntryContent);
      }
    }
  
    entryCreating.current = false;
  };
  
  const getLatestEntryDate = (entries) => {
    const latestEntryDate = new Date(entries.sort((a, b) => new Date(b.date) - new Date(a.date))[0].date);
    return latestEntryDate || new Date();
  };
  
  const sortMessagesByDate = (messages) => 
    messages.sort((a, b) => a.date.toMillis() - b.date.toMillis());
  
  const getDiaryEntryDate = (sortedMessages) => 
    sortedMessages[sortedMessages.length - 1].date.toDate().toLocaleDateString('en-US');
  
  const findEntryByDate = (entries, date) => 
    entries.find(entry => new Date(entry.date).toLocaleDateString('en-US') === date);
  
    const createPrompt = (sortedMessages, entries, characterName) => {
      let totalCharacters = 0;
      const mappedMessages = sortedMessages
        .map(msg => {
          const formattedMessage = formatMessage(msg);
          // Check if formatMessage(msg) returned a value
          if (formattedMessage) {
            // If adding this message would exceed the limit, return null to skip it
            if (totalCharacters + formattedMessage.length > 3000) return null;
            // Update the total characters counter
            totalCharacters += formattedMessage.length;
            // Return the formatted message
            return formattedMessage;
          }
          // Return null if formatMessage(msg) did not return a value
          return null;
        })
        .filter(Boolean)
        .join('\n');
      
      const lastDiaryEntry = getLastDiaryEntry(entries);
      
      return `${mappedMessages}
      ### Input: Last diary entry: ${lastDiaryEntry}
      #### Instruction: Write the ${entries.length}. diary entry as ${characterName} only about the above chat with up to 80 words.
      ### Response 
      Dear Diary,`;
    };
    
    const getLastDiaryEntry = (entries) => {
      if (entries.length > 0) {
        // Sort the entries by date, select the latest one, and return its content
        return entries.sort((a, b) => new Date(b.date) - new Date(a.date))[0].content;
      } else {
        // Return a default message if there are no previous entries
        return "No previous entries.";
      }
    };
  
    const formatMessage = (msg) => {
      let sender = containsNumbers(msg.senderId) 
          ? userData?.FormData?.name || "Onichan" 
          : msg.senderId;
      return msg.text !== '' && (!msg.locked || userData?.subscription !== 'None') 
          ? `${sender}: ${msg.text.trim()}` 
          : null;
  };
  
  const createNewEntry = async (currentEntries, diaryEntryDate, diaryEntryContent) => {
    const diaryEntry = {
      id: uuidv4(),
      content: diaryEntryContent,
      date: diaryEntryDate,
    };
  
    currentEntries[characterName].push(diaryEntry);
  
    const db = getFirestore();
    const userRef = doc(db, 'users', currentUser.uid);
  
    await setDoc(userRef, { diaryEntries: currentEntries }, { merge: true });
    setDiaryEntries(currentEntries);
  };
  
  
  

  useEffect(() => {
    console.log("chatData", chatData)
    const fetchDiaryEntries = async () => {
        let fetchedDiaryEntries = userData?.diaryEntries || {};

        if (!entryCreating.current) {
          await createDiaryEntry(fetchedDiaryEntries);
        }

        setDiaryEntries(fetchedDiaryEntries);
      
    };
  
    fetchDiaryEntries();
  }, []);

  const handleThumbsUp = (entryId, e) => {
    e.stopPropagation(); // Prevent entry selection
    setLikedEntries(prev => ({
      ...prev,
      [entryId]: !prev[entryId]
    }));
    // If entry was disliked, remove the dislike
    if (dislikedEntries[entryId]) {
      setDislikedEntries(prev => ({
        ...prev,
        [entryId]: false
      }));
    }
  };

  const handleThumbsDown = (entryId, e) => {
    e.stopPropagation(); // Prevent entry selection
    setDislikedEntries(prev => ({
      ...prev,
      [entryId]: !prev[entryId]
    }));
    // If entry was liked, remove the like
    if (likedEntries[entryId]) {
      setLikedEntries(prev => ({
        ...prev,
        [entryId]: false
      }));
    }
  };


  return (
    <div 
      className={`fixed inset-0 bg-gradient-to-b from-[#6666ff]/80 to-[#4d4dff]/80 transition-transform duration-300 ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
      style={{ 
        zIndex: 9999,
        backdropFilter: 'blur(8px)',
        borderRight: '1px solid rgba(255, 255, 255, 0.2)'
      }}
    >
      <div className="h-full flex flex-col max-w-3xl mx-auto p-6">
        {/* Header */}
        <div className="flex items-center justify-between mb-8">
          <button 
            onClick={handleClose}
            className="absolute top-6 right-6 text-white hover:text-white transition-colors bg-white/20 rounded-full p-2 hover:bg-white/30 border border-white/20"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <h1 className="text-3xl font-semibold text-white mx-auto">Diary</h1>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto">
          {!selectedEntry ? (
            <div className="space-y-4">
              {diaryEntries[characterName]?.length ? (
                diaryEntries[characterName].map(entry => (
                  <div 
                    key={entry.id}
                    onClick={() => setSelectedEntry(entry)}
                    className="bg-white/20 rounded-2xl p-6 cursor-pointer hover:bg-white/25 transition-colors border border-white/20"
                  >
                    <div className="flex items-center justify-between mb-3">
                      <h3 className="text-xl font-medium text-white">
                        {new Date(entry.date).toLocaleDateString('en-US', { 
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric'
                        })}
                      </h3>
                      <div className="flex gap-3">
                        <button 
                          onClick={(e) => handleThumbsUp(entry.id, e)}
                          className={`p-2 rounded-full transition-colors border border-white/20 
                            ${likedEntries[entry.id] 
                              ? 'bg-green-500/50 hover:bg-green-500/60 border-green-400' 
                              : 'bg-white/20 hover:bg-white/30 border-white/20'}`}
                        >
                          <ThumbsUp className="w-4 h-4 text-white" />
                        </button>
                        <button 
                          onClick={(e) => handleThumbsDown(entry.id, e)}
                          className={`p-2 rounded-full transition-colors border border-white/20 
                            ${dislikedEntries[entry.id] 
                              ? 'bg-red-500/50 hover:bg-red-500/60 border-red-400' 
                              : 'bg-white/20 hover:bg-white/30 border-white/20'}`}
                        >
                          <ThumbsDown className="w-4 h-4 text-white" />
                        </button>
                      </div>
                    </div>
                    <p className="text-white line-clamp-2">
                      {entry.content}
                    </p>
                  </div>
                ))
              ) : (
                <div className="text-center text-white mt-10">
                  <p className="text-lg">No diary entries yet.</p>
                  <p className="text-sm mt-2">Entries will appear here as you chat.</p>
                </div>
              )}
            </div>
          ) : (
            <div className="bg-white/20 rounded-2xl p-6 border border-white/20">
              <div className="flex items-center justify-between mb-6">
                <button 
                  onClick={() => setSelectedEntry(null)}
                  className="flex items-center gap-2 text-white hover:text-white bg-white/20 px-4 py-2 rounded-full hover:bg-white/30 transition-colors border border-white/20"
                >
                  <ChevronLeft className="w-5 h-5" />
                  <span>Back</span>
                </button>
                <div className="flex gap-3">
                  <button 
                    onClick={(e) => handleThumbsUp(selectedEntry.id, e)}
                    className={`p-2 rounded-full transition-colors border border-white/20 
                      ${likedEntries[selectedEntry.id] 
                        ? 'bg-green-500/50 hover:bg-green-500/60 border-green-400' 
                        : 'bg-white/20 hover:bg-white/30 border-white/20'}`}
                  >
                    <ThumbsUp className="w-4 h-4 text-white" />
                  </button>
                  <button 
                    onClick={(e) => handleThumbsDown(selectedEntry.id, e)}
                    className={`p-2 rounded-full transition-colors border border-white/20 
                      ${dislikedEntries[selectedEntry.id] 
                        ? 'bg-red-500/50 hover:bg-red-500/60 border-red-400' 
                        : 'bg-white/20 hover:bg-white/30 border-white/20'}`}
                  >
                    <ThumbsDown className="w-4 h-4 text-white" />
                  </button>
                </div>
              </div>
              <h2 className="text-2xl font-semibold text-white mb-4">
                {new Date(selectedEntry.date).toLocaleDateString('en-US', { 
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })}
              </h2>
              <p className="text-white leading-relaxed">
                {selectedEntry.content}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Diary;