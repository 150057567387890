import React from 'react';
import { ChevronRight } from 'lucide-react';
import "../../styles/ProUnlockBanner.scss";
import halfLogo from '../../img/half-logo.svg';

const ProUnlockBanner = ({ onClick }) => {
    return (
      <div 
        onClick={onClick}
        className="pro-unlock-banner flex items-center gap-2 bg-white/20 backdrop-blur-md 
          rounded-2xl p-3 cursor-pointer hover:bg-white/25 transition-all duration-300 
          border border-white/20 shadow-lg animate-slideIn"
      >
        <div className="flex-shrink-0 w-8 h-8 lg:w-10 lg:h-10 bg-indigo-500 rounded-full overflow-hidden">
          <img 
            src="https://pbs.twimg.com/profile_images/1638970434749100040/1dhRZJ1-_400x400.jpg"
            alt="PRO"
            className="w-full h-full object-cover"
          />
        </div>
        
        <div className="flex-1 min-w-0">
          <h3 className="text-white font-semibold text-xs lg:text-sm mb-0.5 lg:mb-1 truncate">
            Unlock Oniichat PRO
          </h3>
          <p className="text-white/80 text-xs leading-tight line-clamp-2 banner-description">
            Get access to the advanced model, unlimited voice messages, image generation, activities, and more.
          </p>
        </div>
  
        <ChevronRight className="w-4 h-4 lg:w-5 lg:h-5 text-white/60 flex-shrink-0" />
      </div>
    );
  };

// Add the animation keyframes to your global CSS or style tag
const styles = `
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

// Add the style tag to the document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default ProUnlockBanner;