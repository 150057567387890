import { useState, useContext, useEffect } from 'react';
import Nav from "../../components/Common/MainNav";
import FAQ from "../../components/Shop/FAQ";
import multipleGems from "../../img/multipleGems.png";
import LoaderModal from '../../components/Common/LoaderModal';
import Footer from "../../components/Common/Footer";
import { SubscriptionPanel, getFeaturesForAnnualPlan } from "../../components/SubscriptionPanel";
import { SingleSubscriptionPanelNew } from "../../components/SingleSubscriptionPanelNew";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  onSnapshot,
  FieldValue                               
} from "firebase/firestore";
import { logEvent } from "../../firebase";
import { db } from "../../firebase";
import { useNavigate, Redirect } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import antivirus from "../../img/verified.png";
import bank from "../../img/bank-security.png";

const Gems = () => {
  const { currentUser, userData } = useContext(AuthContext);
  const [showNav, setShowNav] = useState(false);
  const navigate = useNavigate();
  const [basicOwned, setBasicOwned] = useState(false);
  const [plusOwned, setPlusOwned] = useState(false);
  const [ultimateOwned, setUltimateOwned] = useState(false);
  const [currentPlan, setCurrentPlan] = useState('');
  const [annualOwned, setAnnualOwned] = useState(false);
  useEffect(() => {
    let plan = '';
    if(basicOwned) plan = "Basic Plan";
    else if(plusOwned) plan = "Plus Plan";
    else if(ultimateOwned) plan = "Ultimate Plan";
    setCurrentPlan(plan);
    console.log("plan", plan)
  }, [basicOwned, plusOwned, ultimateOwned]);
  
  const cancelSubscription = async () => {
    if (!currentUser.isAnonymous) {
      throw new Error("You must be logged in to cancel your subscription");
    }
    const userDoc = await getDoc(doc(db, "users", currentUser.uid));
    const userData = userDoc.data();
    
    if (!userData || userData.subscription === "None") {
      throw new Error("You don't have an active subscription");
    }
    
    const data = {
      subscription_id: userData.subscription_id,
      uid: currentUser.uid
    };
    
    const res = await fetch('https://fathomless-gorge-94679.herokuapp.com/api/cancelsubscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) 
    });
  
    if (!res.ok) {
      const error = await res.text();
      throw new Error(error);
    }
  };
  // Define features for each plan
  const featuresForAnnual = getFeaturesForAnnualPlan();
const featuresForBasic = [
  { text: '1500 gems/month', available: true },
  { text: 'Unlock all images, including NSFW content, for free.', available: true },
  { text: 'Unlock blurred NSFW messages for free.', available: true },
  { text: '1 gem messages', available: false },
  { text: 'Experience lightning-fast loading times.', available: false },
  { text: 'Advanced AI language Model', available: false }
];

const featuresForPlus = [
  { text: '4,200 gems/month', available: true },
  { text: 'Unlock all images, including NSFW content, for free.', available: true },
  { text: 'Unlock blurred NSFW messages for free.', available: true },
  { text: '1 gem messages', available: true },
  { text: 'Experience lightning-fast loading times.', available: false },
  { text: 'Advanced AI language Model', available: false }
];

const featuresForUltimate = [
  { text: '8,250 gems/month', available: true },
  { text: 'Unlock all images, including NSFW content, for free.', available: true },
  { text: 'Unlock blurred NSFW messages for free.', available: true },
  { text: 'Infinite free Messages', available: true },
  { text: 'Experience lightning-fast loading times.', available: true },
  { text: 'Advanced AI language Model', available: true }
];

// Define upgrade click handlers for each plan
const onUpgradeClickBasic = async () => {
  logEvent('Lead'); // Log the "Lead" event
  await UpgradeSubscription("Basic Plan", "4.99", "subscription", "P-49219165H1782652TMPGEBYY");
};

const onUpgradeClickPlus = async () => {
  logEvent('Lead'); // Log the "Lead" event
  await UpgradeSubscription("Plus Plan", "7.99", "subscription", "P-5F5211400S610710XMPGEBRQ");
};

const onUpgradeClickUltimate = async () => {
  logEvent('Lead'); // Log the "Lead" event
  await UpgradeSubscription("Ultimate Plan", "9.99", "subscription", "P-6D0885295V0711039MPGEA3A");
};

useEffect(() => {
  logEvent('Custom', { customEventName: 'ShopPageVisit' }); // Log the "ShopPageVisit" event

  if (userData) {
    console.log("userData type", userData.type);
  }

  var unSub = function() { };
  if (currentUser !== null && currentUser.uid) {
    unSub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
      localStorage.setItem("paymentProcessing", "None");
      console.log("data", doc.data());

      if (doc.exists()) {
        const data = doc.data();

        if (data && data.subscription) {
          if (data.subscription === "Basic Plan") {
            setBasicOwned(true);
            setPlusOwned(false);
            setUltimateOwned(false);
          } else if (data.subscription === "Plus Plan") {
            setBasicOwned(false);
            setPlusOwned(true);
            setUltimateOwned(false);
          } else if (data.subscription === "Ultimate Plan") {
            setBasicOwned(false);
            setPlusOwned(false);
            setUltimateOwned(true);
          } else {
            setBasicOwned(false);
            setPlusOwned(false);
            setUltimateOwned(false);
          }
        } else {
          setBasicOwned(false);
          setPlusOwned(false);
          setUltimateOwned(false);
        }
      } else {
        console.error("Document does not exist");
        setBasicOwned(false);
        setPlusOwned(false);
        setUltimateOwned(false);
      }
    });
  }

  return () => {
    unSub();
  };
}, [currentUser]);

  const UpgradeSubscription = async (newPlanName, newPlanPrice, type="subscription", productID) => {
    let currentPlan = '';
    if(basicOwned) currentPlan = "Basic Plan";
    else if(plusOwned) currentPlan = "Plus Plan";
    else if(ultimateOwned) currentPlan = "Ultimate Plan";
    

      // After successful cancellation (or if there was no active subscription to cancel), buy new subscription
      try {
        await BuyOffer(newPlanName, newPlanPrice, type, productID);
      } catch(error) {
        console.error("Error buying subscription: ", error);
      }
  }
  
  const planValue = (planName) => {
    switch(planName) {
      case "Basic Plan": return 1;
      case "Plus Plan": return 2;
      case "Ultimate Plan": return 3;
      default: return 0; // No subscription
    }
  }
  
  const GemItem = ({ gems, price, onClick, img, bannerText, saleText}) => (
    <div className="gemItem">
      {bannerText ? ( <div className='banner'><span className='bannerText'>{bannerText}</span></div>) : (<div></div>)}  
      {saleText ? (<div className='saleGems'><span>{saleText}</span></div>) :(<div></div>)}
      <img src={multipleGems} alt="" />
      <div className="gems">{gems}</div>
      <div className="priceButton" onClick={onClick}>
        {price}$
      </div>
    </div>
  );
  const BuyOffer = async (productName, productPrice, type="product", productID="GEMS") => {
    if (currentUser.isAnonymous) {
      navigate("/login");
    } else {
    window.trackExoClickConversion('Lead')
    window.trackMetaConversion('Lead');
    logEvent('Lead'); // Log the "Lead" event
    window.trackTwitterConversion("checkoutStart")
    logEvent('clicked on checkout');
      navigate(`/checkout/${productName}/${productPrice}/${type}/${productID}`);
    }
}
// Add a new function to handle annual plan upgrades
const onUpgradeClickAnnual = async () => {
  logEvent('Lead'); // Log the "Lead" event
  await UpgradeSubscription("Annual Plan", "99.99", "subscription", "P-ANNUALSUBSCRIPTIONID");
};

  return (
    <div>
        {localStorage.getItem("paymentProcessing") === "true" && currentUser?.uid ? (<LoaderModal/>) :(<></>) }
    <div className='abos'>
<Nav/>
    <div className='wrapper'>
    <div className='content'>
    <div className='title'>
    <h1>Oniichat Ultimate</h1>
    <div className='subtitle'>Subscribe now and bring joy to your Waifu! Cancel at anytime!</div>
    </div>
{/* Buy Anonymously Section */}
<div className="buy-anonymously-section">
    <h2><i className="fas fa-user-secret"></i> Buy Anonymously</h2>
    <p>Enjoy our services without revealing your identity. Your privacy matters to us. Charges will also appear anonymously on your billing statement.</p>
</div>
            
            <div className="pricing">
  <div className="container">
    <div className="row justify-content-center">
      {(plusOwned || basicOwned) && (
        <div className="col-lg-4">
          <SubscriptionPanel
            planName="Basic Plan"
            planPrice="$4.99"
            currentPlan={currentPlan}
            onUpgradeClick={onUpgradeClickBasic}
            features={featuresForBasic}
            saleValue="4x"
            isBestValue={false}
            strikethroughPrice="375"
          />
        </div>
      )}
      {(plusOwned || basicOwned) && (
        <div className="col-lg-4">
          <SubscriptionPanel
            planName="Plus Plan"
            planPrice="$7.99"
            currentPlan={currentPlan}
            onUpgradeClick={onUpgradeClickPlus}
            features={featuresForPlus}
            saleValue="7x"
            isBestValue={false}
            strikethroughPrice="600"
          />
        </div>
      )}
      {(plusOwned || basicOwned) && (
        <div className="col-lg-4">
          <SubscriptionPanel
            planName="Ultimate Plan"
            planPrice="$9.99"
            currentPlan={currentPlan}
            onUpgradeClick={onUpgradeClickUltimate}
            features={featuresForUltimate}
            saleValue="11x"
            isBestValue={true}
            strikethroughPrice="600"
          />
        </div>
      )}
      {!basicOwned && !plusOwned && (
  <div className="col-12 d-flex justify-content-center">
    <SingleSubscriptionPanelNew
      currentPlan={
        userData && userData.subscription !== "None"
          ? userData.type || "monthly"
          : "annual"
      }
      isSubscribed={userData && userData.subscription !== "None"}
    />
  </div>
)}
<div className='security-icons'>
  <div className='anti-virus'>
    <img src={antivirus} alt="antivirus icon" className='anti-virus-icon'/>
    <div className='text-wrapper'>
      <p>Antivirus</p>
      <p className='secured'>Secured</p>
    </div>
  </div>
  <div className='bank-statement'>
    <img src={bank} alt="bank security icon" className='bank-statement-icon'/>
    <div className='text-wrapper'>
      <p>Privacy in bank</p>
      <p className='statement'>statement</p>
    </div>
  </div>
</div>



    </div>
  </div>
</div>
    <div className='title'>
    <h1>Gems</h1>
      </div>
      <div className="gemGrid">
      <GemItem gems={750} price="9.99" onClick={async () => await BuyOffer("750 Gems", "9.99")} img="https://1637693156.rsc.cdn77.org/web/main-page/gems_50.png"/>
      <GemItem gems={1500} price="14.99" onClick={async () => await BuyOffer("1500 Gems", "14.99")} img="https://1637693156.rsc.cdn77.org/web/main-page/gems_100.png" bannerText="Popular" saleText="50% OFF"/>
      <GemItem gems={3750} price="19.99" onClick={async () => await BuyOffer("3750 Gems", "19.99")} img="https://1637693156.rsc.cdn77.org/web/main-page/gems_250.png"/>
      <GemItem gems={7500} price="29.99" onClick={async () => await BuyOffer("7500 Gems", "29.99")} img="https://1637693156.rsc.cdn77.org/web/main-page/gems_500.png"/>
      <GemItem gems={15000} price="49.99" onClick={async () => await BuyOffer("15000 Gems", "49.99")} img="https://1637693156.rsc.cdn77.org/web/main-page/gems_1000.png" bannerText="Best Value" saleText="83% OFF"/>
      </div>
      <div className="container">
      <div class="row"><h4 class="text-justify">If you have any questions or problems please contact our 24/7 support team at support@oniichat.com</h4></div>
      </div>
    <FAQ/>
   <Footer/>
    </div>
</div>
</div>
</div>

  )
}

export default Gems;