import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { logEvent } from "../firebase";
import '../styles/SingleSubscriptionPanel.scss'; // Ensure the correct path to your SCSS file
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import catgirl from "../img/Koneko.svg";
import maid from "../img/TsundereMaid.svg";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  Timestamp,
  runTransaction,
} from 'firebase/firestore';

export const SingleSubscriptionPanelNew = ({ isSubscribed, currentPlan }) => {
  const { currentUser, userData } = useContext(AuthContext);
  const [endTime, setEndTime] = useState(null);
  const endTimeRef = useRef(null); // Initialize with null
  const navigate = useNavigate();

  const [selectedPlan, setSelectedPlan] = useState(currentPlan || 'annual'); // Initialize with current
  const [subscriptionTerms, setSubscriptionTerms] = useState('');

  // Define subscription terms
  const monthlyTerms = ""; /*"You will be billed $9.99 per month until you cancel the subscription.*/
  const annualTerms = ""; /*You will be billed $59.88 today which covers your subscription for the next 12 months. After 12 months, you will be automatically billed $59.88 annually until you cancel.*/ 
  const [timeLeft, setTimeLeft] = useState(null); // Initially null until fetched

  const features = [
    { text: "8,250 gems/month", available: true },
    { text: "Unlimited Roleplay", available: true },
    { text: "Unlock All NSFW Content", available: true },
    { text: "Infinite Free Messages", available: true },
    { text: 'Unlock "Your Waifu"', available: true },
    { text: "Advanced AI language Model", available: true },
    { text: "Unlock Voice Messages", available: true },
    { text: "All Features Unlocked", available: true },
    // Add more features as needed
  ];

  const INITIAL_DURATION = 131400000; // 1.5 days in milliseconds

  // Format the remaining time into days, hours, minutes, and seconds
  const formatTimeLeft = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (24 * 3600));
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const { days, hours, minutes, seconds } = formatTimeLeft(timeLeft || 0);
  const formattedTime = `${days.toString().padStart(2, '0')}d ${hours
    .toString()
    .padStart(2, '0')}h ${minutes.toString()
    .padStart(2, '0')}m ${seconds.toString()
    .padStart(2, '0')}s`;

  // Update selectedPlan when currentPlan changes
  useEffect(() => {
    setSelectedPlan(currentPlan || 'annual');
  }, [currentPlan]);

  // Fetch and set endTime using Firestore Transactions
  const fetchAndSetEndTime = useCallback(async () => {
    if (!currentUser) {
      console.log("No user is logged in.");
      return;
    }

    const db = getFirestore();
    const userDocRef = doc(db, 'users', currentUser.uid);
    const now = Date.now();

    try {
      await runTransaction(db, async (transaction) => {
        const userDoc = await transaction.get(userDocRef);

        let fetchedEndTime;

        if (userDoc.exists()) {
          const data = userDoc.data();
          if (data.endTime && typeof data.endTime.toMillis === 'function') {
            fetchedEndTime = data.endTime.toMillis();
            console.log("Fetched endTime from Firestore:", new Date(fetchedEndTime).toLocaleString());
          } else {
            console.log("endTime not found or invalid in Firestore.");
          }
        } else {
          console.log("User document does not exist in Firestore.");
        }

        // If no valid endTime is found or it has expired, set a new one
        if (!fetchedEndTime || fetchedEndTime <= now) {
          fetchedEndTime = now + INITIAL_DURATION;
          transaction.set(userDocRef, { endTime: Timestamp.fromMillis(fetchedEndTime) }, { merge: true });
          console.log("Set new endTime:", new Date(fetchedEndTime).toLocaleString());
        }

        // Update component state
        setEndTime(fetchedEndTime);
        setTimeLeft(fetchedEndTime - now);
        endTimeRef.current = fetchedEndTime;
      });
    } catch (error) {
      console.error("Transaction failed: ", error);
      // Fallback mechanism
      try {
        const defaultEndTime = Date.now() + INITIAL_DURATION;
        await updateDoc(userDocRef, { endTime: Timestamp.fromMillis(defaultEndTime) });
        setEndTime(defaultEndTime);
        setTimeLeft(INITIAL_DURATION);
        endTimeRef.current = defaultEndTime;
        console.log("Set default endTime due to error:", new Date(defaultEndTime).toLocaleString());
      } catch (updateError) {
        console.error("Error setting default endTime:", updateError);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    fetchAndSetEndTime();
  }, [fetchAndSetEndTime]);

  // Initialize the timer after endTime is set
  useEffect(() => {
    if (!endTime) return;

    const timerInterval = setInterval(() => {
      const now = Date.now();
      const remaining = endTimeRef.current - now;

      if (remaining <= 1000) { // When 1 second or less is remaining
        clearInterval(timerInterval);
        console.log("Timer is at 00:00:00:01. Restarting the timer.");
        fetchAndSetEndTime(); // Restart the timer by setting a new endTime
      } else {
        setTimeLeft(remaining);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [endTime, fetchAndSetEndTime]); // Added fetchAndSetEndTime to dependencies

  // Update subscription terms based on the selected plan
  useEffect(() => {
    setSubscriptionTerms(selectedPlan === 'annual' ? annualTerms : monthlyTerms);
  }, [selectedPlan]);

  // Handle plan selection
  const handlePlanSelection = (plan) => {
    if (!isSubscribed) {
      setSelectedPlan(plan);
    }
  };

  // Handle subscription button click
  const OnSubscribe = () => {
    if (currentUser?.isAnonymous) {
      navigate("/login");
      return;
    }
    logEvent('Lead'); // Log the "Lead" event
    window.trackExoClickConversion('Lead');
    /*window.trackMetaConversion('Lead');*/
    window.trackMetaConversion('InitiateCheckout');
    window.trackTwitterConversion("checkoutStart");


    if (selectedPlan === "monthly") {
      navigate(`/checkout/Ultimate Plan/9.99/subscription/P-6D0885295V0711039MPGEA3A`);
    } else {
      navigate(`/checkout/Annual Plan/59.88/subscription/P-5DU33528TH181570PMVUN5EQ`);
    }
  };

  return (
    <div id='oniichat-pp-wrapper'>
      {/* Conditionally render the timer only if the user is not subscribed */}
      {!isSubscribed && (
        <div className='timer'>
          <p className='timer-text'>
            {timeLeft === null ? 'Loading...' : formattedTime}
          </p>
          <p className='save-money'>Limited Offer: Save 50%!!</p>
        </div>
      )}
      
      {!isSubscribed && (
        <div className='Save'>
          <h4 className='Header1'>
            Get An <span className='Red'>Exclusive</span>
          </h4>
          <h4 className='Header2'>
            <span className='Red'>Discount</span> Only Today!
          </h4>
          <p className='Paragraph1'>Commit to your Waifus🤗 and save:</p>
          <p className='Paragraph2'>
            Up to <span className='highlight-red'>70%</span> off for buying Annual
          </p>
        </div>
      )}
      
      <div className='oniichat-plus-panel'>
        <img src={catgirl} alt="Catgirl" className="side-image left-image" />
      </div>
      
      <div className="subscription-content">
        <img src={maid} alt="Maid" className="side-image right-image" />
      </div>
      
      <div className='oniichat-plus-panel'>
        <div className="subscription-content">
          <h2 className='onii-ultimate'>Oniichat Ultimate</h2>
          <ul className="features">
            {features.map((feature, index) => (
              <li key={index} className={feature.available ? '' : 'unavailable'}>
                {feature.text}
              </li>
            ))}
          </ul>
          
          {!isSubscribed && (
            <div className="plan-selection">
              <button
                className={`plan-option ${selectedPlan === 'annual' ? 'selected' : ''}`}
                onClick={() => handlePlanSelection('annual')}>
                12 Months
                <span className="price price-one">$4</span>
                <span className='cent cent-one'>.99</span>
                <span className='month month-one'>/month</span>
                <span className="badge1">70% OFF</span>
                <p className="annual-writing">Was 17.99/month</p>
              </button>
              
              <button
                className={`plan-option ${selectedPlan === 'monthly' ? 'selected' : ''}`}
                onClick={() => handlePlanSelection('monthly')}>
                1 Month
                <span className="price price-two">$9</span>
                <span className='cent cent-two'>.99</span>
                <span className='month month-two'>/month</span>
                <span className="badge2">50% OFF</span>
                <p className="annual-writing">Was 17.99/month</p>
              </button>
            </div>
          )}
          
          {!isSubscribed && (
            <div className="termsText">
              <p>{subscriptionTerms}</p>
            </div>
          )}
          
          {/* Conditionally render the limited offer and second timer */}
          {!isSubscribed && (
            <>
              <div className='limited-of'>
                Limited Offer: Save 50%!! - Annual
              </div>
              <div className='timer-two'>
                {timeLeft === null ? 'Loading...' : formattedTime}
              </div>
            </>
          )}
          
          <div className="subscribe-button-container">
            {isSubscribed ? (
              <div className="subscribed-message">
                <p>You are currently subscribed to the {selectedPlan === 'annual' ? 'Annual' : 'Monthly'} plan.</p>
              </div>
            ) : (
              <button className="subscribe-button" onClick={OnSubscribe}>
                Subscribe Now
              </button>
            )}
          </div>
          
          {!isSubscribed && (
            <p className="cancel-info">Cancel anytime. Renews {selectedPlan === 'annual' ? 'annually' : 'monthly'}.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleSubscriptionPanelNew;
