import React, { useEffect, useState, useCallback } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Chat from './components/Chat/Chat';
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import "./styles/style.scss";
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { doc, updateDoc } from 'firebase/firestore';
import { db } from './firebase'; // Assuming you have a firebase.js file with your Firebase configuration
import { useContext } from "react";
import Landing from "./pages/Other/Landing";
import Shop from "./pages/Shop/Shop";
import TOS from "./pages/Legal/TOS";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";
import FilterableContentPage from "./pages/Chat/FilterableContentPage";
import Checkout from "./components/Shop/Checkout";
import SettingsPage from './components/Settings/SettingsPage';
import VotingCharacters from './pages/Voting/VotingCharacters';
import HallOfFame from './pages/Voting/HallOfFame';
import MultiStepForm from "./pages/Forms/MultiStepForm";
import YourWaifu from './pages/YourWaifu';
import EditYourWaifu from './pages/EditYourWaifu';
import ChatPage from './pages/Other/ChatPage';
import { Modal } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import CreateWaifuPersonality from './pages/CreateWaifuPersonality';
import FirstCancelWarning from './pages/FirstCancelWarning';
import WaifuNameForm from './pages/Forms/WaifuNameForm';
import ProfilePage from './pages/ProfilePage';
import Dashboard from './GemAnalytics/Dashboard';
import CookieConsentComponent from './components/CookieConsentComponent';
import CallComponent from './components/Chat/CallComponent';
import FeatureUpvotingPage from './components/FeatureUpvotingPage.jsx';
import { AuthContext } from "./context/AuthContext";
import { ChatContextProvider } from "./context/ChatContext";
import tracking from './tracking/index.js';
import "./ExoClickUtil.js"
import "./TwitterUtil.js"
import './tailwind.css';
import './metaPixel.js';  // Add this import at the top

const queryClient = new QueryClient();

const initializeTapfiliate = () => {
  // Initialize Tapfiliate
  (function(t, a, p) {
    t.TapfiliateObject = a;
    t[a] = t[a] || function() {
      (t[a].q = t[a].q || []).push(arguments);
    };
  })(window, 'tap');

  // Initialize with your account
  window.tap('create', '50272-74a2c0', { integration: "paypal" });
  window.tap('detect');
};

const ExtractConversionTracking = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filteredParams = tracking.extractTrackingParams(params);
    if (filteredParams !== undefined) {
      navigate({
        search: new URLSearchParams(filteredParams).toString(),
      }, { replace: true });
    }
  }, [location.search, navigate]);

  return children;
};

function App() {
  const ProtectedRoute = ({ children }) => {
    return children;
  };

  useEffect(() => {
    // Function to load external script
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script ${src}`));
        document.body.appendChild(script);
      });
    };

    // Load Tapfiliate script if not already loaded
    if (!window.tap) {
      loadScript('https://script.tapfiliate.com/tapfiliate.js')
        .then(() => {
          initializeTapfiliate();
        })
        .catch((error) => {
          console.error('Error loading Tapfiliate script:', error);
        });
    }
  }, []);

  const renderFilterableContentPage = useCallback(
    () => (
      <ProtectedRoute>
        <FilterableContentPage />
      </ProtectedRoute>
    ),
    []
  );

  return (
    <>
      <CookieConsentComponent />
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
          <BrowserRouter>
            <ChatContextProvider>
              <ExtractConversionTracking>
                <Routes>
                  <Route path="/" element={renderFilterableContentPage()} />
                  <Route path="featurevoting" element={<FeatureUpvotingPage />} />
                  <Route path="call" element={<CallComponent />} />
                  <Route path="login" element={<Login />} />
                  <Route path="customProfilePage" element={<ProfilePage />} />
                  <Route path="WaifuName" element={<WaifuNameForm />} />
                  <Route path="CancelSubscription" element={<FirstCancelWarning />} />
                  <Route path="yourWaifu" element={<YourWaifu />} />
                  <Route path="EditYourWaifu" element={<EditYourWaifu />} />
                  <Route path="CreateWaifuPersonality" element={<CreateWaifuPersonality />} />
                  <Route path="multistepform" element={<MultiStepForm />} />
                  <Route path="community" element={<VotingCharacters />} />
                  <Route path="hall-of-fame" element={<HallOfFame />} />
                  <Route path="profile" element={<SettingsPage />} />
                  <Route path="selectchat" element={<FilterableContentPage />} />
                  <Route path="register" element={<Register />} />
                  <Route path="test" element={<ChatPage />} />
                  <Route path="chat" element={<Chat />} />
                  <Route path="shop" element={<Shop />} />
                  <Route path="terms-of-use" element={<TOS />} />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="checkout/:productName/:productPrice/:type/:productID" element={<Checkout />} />
                  <Route path="gemAnalytics" element={<Dashboard />} />
                </Routes>
              </ExtractConversionTracking>
            </ChatContextProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;