// constants.js
export const PLAN_NAMES = {
    BASIC: "Basic Plan",
    PLUS: "Plus Plan",
    ULTIMATE: "Ultimate Plan"
};

export const ENDPOINTS = {
    CANCEL_SUBSCRIPTION: 'https://fathomless-gorge-94679.herokuapp.com/api/cancelsubscription'
};

// export const BACKEND_URL = 'http://localhost:8000';
export const BACKEND_URL = 'https://fathomless-gorge-94679.herokuapp.com';