import React, { useState, useEffect, useContext } from 'react';
import { collection, doc, getDoc, updateDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { db, logEvent } from '../../firebase';
import { AuthContext } from "../../context/AuthContext";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import multipleGems from "../../img/multipleGems.png"
import { ChatContext } from "../../context/ChatContext";
import twitter from "../../img/shopPromo/Logo_of_Twitter.svg.png"
import discordLogo from "../../img/shopPromo/Logo_of_Discord.svg"
import { Gem, X, Discord, Heart } from 'lucide-react';

import huggingFaceLogo from "../../img/shopPromo/huggingface_logo-noborder.svg"; // Update the path as needed
import "../../styles/DailyRewards.scss"


const rewards = [
    { day: 1, gems: 10 },
    { day: 2, gems: 20 },
    { day: 3, gems: 20 },
    { day: 4, gems: 30 },
    { day: 5, gems: 50 },
    { day: 6, gems: 70 },
];

function formatTimeRemaining(date) {
    const remainingTime = (new Date(date.getTime() + 24 * 60 * 60 * 1000)) - new Date();
    const remainingHours = Math.floor(remainingTime / (60 * 60 * 1000));
    const remainingMinutes = Math.floor((remainingTime - remainingHours * 60 * 60 * 1000) / (60 * 1000));
    return `${padZero(remainingHours)}:${padZero(remainingMinutes)} h`;
}

function padZero(number) {
    return number.toString().padStart(2, '0');
}

const DayDifference = (date1, date2) => {
    const differenceInMilliseconds = Math.abs(date1 - date2);
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return differenceInDays;
}

function DailyRewards({show, onShow, onClose}) {
    const { currentUser, userData } = useContext(AuthContext);
    const [postLink, setPostLink] = useState(true);
    const [activeTab, setActiveTab] = useState('rewards');

    const GemItem = ({ gems, day, onClick }) => {
        const isClaimed = userData?.day >= day;
        const isClaimable = (userData?.day + 1 === day && userData?.lastClaimed && DayDifference(userData?.lastClaimed.toDate(), new Date()) > 0.99) || (userData?.day === 0 && day === 1);
        const timeRemaining = userData?.lastClaimed && formatTimeRemaining(userData?.lastClaimed.toDate());
    
        return (
            <div className={`reward-card ${isClaimed ? 'claimed' : ''} ${isClaimable ? 'claimable' : ''}`}>
                <div className="reward-day">Day {day}</div>
                <div className="reward-gems">
                    <img 
                        src={multipleGems} 
                        alt="Gems" 
                        className="gem-icon" 
                        style={{ 
                            width: '50px',
                            height: '50px',
                            objectFit: 'contain',
                            margin: '10px -12px'
                        }} 
                    />
                    <span style={{ 
                        fontSize: '1.5rem',
                        fontWeight: '600',
                        marginLeft: '8px',
                        marginTop: '2px'
                    }}>{gems}</span>
                </div>
                <div className="reward-status">
                    {isClaimed ? (
                        <div className="claimed-badge">
                            <span>Claimed</span>
                        </div>
                    ) : isClaimable ? (
                        <button className="claim-button" onClick={onClick}>
                            Claim Reward
                        </button>
                    ) : userData?.day + 1 === day ? (
                        <div className="timer">{timeRemaining}</div>
                    ) : (
                        <div className="locked">
                            <span>Locked</span>
                        </div>
                    )}
                </div>
            </div>
        );
    };
    
    

    const QuestItem = ({ title, gems, description, onClick, instructions, link, questName, logo }) => {
        const [inputValue, setInputValue] = useState('');
        const isQuestClaimedForCurrentPost = questName === 'LikeQuest'
            ? (userData?.questsClaimed?.includes(questName) && userData?.lastClaimedPost === link)
            : userData?.questsClaimed?.includes(questName);

        return (
            <div className="quest-card">
                <div className="quest-header">
                    <h3>{title}</h3>
                    <div className="quest-gems">
                    <img 
                        src={multipleGems} 
                        alt="Gems" 
                        className="gem-icon" 
                        style={{ 
                            width: '40px',
                            height: '40px',
                            objectFit: 'contain',
                            margin: '7px -5px 10px -12px'
                        }} 
                    />
                        <span>{gems}</span>
                    </div>
                </div>
                <p className="quest-description">{description}</p>
                {!isQuestClaimedForCurrentPost && (
                    <>
                        <div className="quest-input">
                            <input
                                type="text"
                                placeholder="Paste verification URL here..."
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                        </div>
                        <div className="quest-actions">
                            <button 
                                className="social-link-button"
                                onClick={() => window.open(link, '_blank')}
                            >
                                <img src={logo} alt={title} />
                                <span>Open Link</span>
                            </button>
                            <button 
                                className="claim-button"
                                onClick={() => onClick(inputValue)}
                                disabled={!inputValue}
                            >
                                Claim Reward
                            </button>
                        </div>
                    </>
                )}
                {isQuestClaimedForCurrentPost && (
                    <div className="claimed-badge">
                        <span>Completed</span>
                    </div>
                )}
            </div>
        );
    };

    useEffect(() => {
        async function loadProgress() {
            if (!userData?.uid) return;

            if (!userData?.day) {
                await updateDoc(doc(db, 'users', userData?.uid), { day: 0 });
            }

            if (userData?.lastClaimed) {
                const daysDifference = DayDifference(userData?.lastClaimed.toDate(), new Date());

                if (daysDifference > 1.99) {
                    await updateDoc(doc(db, 'users', userData?.uid), { day: 0 });
                } else if (daysDifference > 0.99) {
                    onShow();
                }
            } else {
                onShow();
            }

            const postLinkDoc = await getDoc(doc(db, 'quests', 'TwitterQuests'));
            if (postLinkDoc.exists()) {
                setPostLink((postLinkDoc.data()).postLink);
            }
        }
        loadProgress();
    }, [userData]);

    async function claimReward(day, gems) {
        var rewardDay = day;
        const addedGems = userData?.gems + gems;
        if (rewardDay === 6) {
            rewardDay = 0;
        }
        logEvent("reward claimed day: " + rewardDay);
        await updateDoc(doc(db, 'users', currentUser.uid), { 
            day: rewardDay, 
            gems: addedGems, 
            lastClaimed: new Date() 
        });
    }

    async function claimQuestReward(inputLink, link, gems, questName) {
        if (!inputLink.includes(link)) {
            alert("Invalid verification link. Please make sure you've completed the quest and copied the correct URL.");
            return;
        }
        const questsClaimed = userData?.questsClaimed && userData?.questsClaimed[userData?.questsClaimed.indexOf(questName)] 
            ? [...userData?.questsClaimed] 
            : userData?.questsClaimed 
            ? [...userData?.questsClaimed, questName] 
            : [questName];
        const addedGems = userData?.gems + gems;

        logEvent(questName + " quest claimed");

        if (questName === 'LikeQuest') {
            await updateDoc(doc(db, 'users', currentUser.uid), { 
                gems: addedGems, 
                questsClaimed, 
                lastClaimedPost: link 
            });
        } else {
            await updateDoc(doc(db, 'users', currentUser.uid), { 
                gems: addedGems, 
                questsClaimed 
            });
        }
    }

    return (
        <Modal 
            show={show} 
            onHide={onClose}
            centered
            className="rewards-modal"
            size="lg"
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Daily Rewards</h2>
                    <button className="close-button" onClick={onClose}>
                        <X size={24} />
                    </button>
                </div>
                
                <div className="modal-tabs">
                    <button 
                        className={`tab ${activeTab === 'rewards' ? 'active' : ''}`}
                        onClick={() => setActiveTab('rewards')}
                    >
                        Daily Rewards
                    </button>
                    <button 
                        className={`tab ${activeTab === 'quests' ? 'active' : ''}`}
                        onClick={() => setActiveTab('quests')}
                    >
                        Quests
                    </button>
                </div>

                <div className="modal-body">
                    {activeTab === 'rewards' ? (
                        <div className="rewards-grid">
                            {rewards.map(({ gems, day }) => (
                                <GemItem 
                                    gems={gems} 
                                    day={day} 
                                    key={day} 
                                    onClick={() => claimReward(day, gems)} 
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="quests-grid">
                            <QuestItem 
                                title="Follow on Twitter" 
                                gems={40} 
                                description="Follow our Twitter account for bonus gems! Note: Unfollowing will result in reward removal." 
                                onClick={(inputLink) => claimQuestReward(
                                    inputLink, 
                                    "https://twitter.com/oniichat?trackId=ufd435zd8d8632", 
                                    40, 
                                    "FollowQuest"
                                )} 
                                link="https://twitter.com/oniichat?trackId=ufd435zd8d8632" 
                                questName="FollowQuest"
                                logo={twitter}
                            />
                            <QuestItem 
                                title="Join Our Discord" 
                                gems={80} 
                                description="Join our Discord community for bonus gems! Note: Leaving the server will remove the reward." 
                                onClick={(inputLink) => claimQuestReward(
                                    inputLink, 
                                    "https://discord.com/invite/Se96PwTVm5", 
                                    80, 
                                    "FollowQuestDiscord"
                                )} 
                                link="https://discord.com/invite/Se96PwTVm5" 
                                questName="FollowQuestDiscord"
                                logo={discordLogo}
                            />
                            <QuestItem 
                                title="Engage & Earn" 
                                gems={30} 
                                description="Like and retweet our latest post for bonus gems! Note: Removing engagement will forfeit the reward." 
                                onClick={(inputLink) => claimQuestReward(
                                    inputLink, 
                                    postLink + "?trackId=75767fg7t8z8h", 
                                    30, 
                                    "LikeQuest"
                                )} 
                                link={postLink + "?trackId=75767fg7t8z8h"} 
                                questName="LikeQuest" 
                                logo={twitter}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
}

export default DailyRewards;