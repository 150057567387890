import React, { useState, useEffect, useContext} from 'react';
import { Modal, Form, Input, Button, Checkbox, Divider, Typography, Space, message } from 'antd';
import { useNavigate, Link } from "react-router-dom";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from "../../firebase";
import { FaGoogle } from 'react-icons/fa';
import { logEvent, db, AuthErrors } from "../../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import styles from '../../styles/SignInOut.module.scss';
import { createChatId, containsNumbers } from '../../helpers';
import { ChatContext } from '../../context/ChatContext'; 
import { CharacterContext } from '../../context/CharacterContext'; 
import { writeBatch } from "firebase/firestore"; // Import writeBatch
import { FaGem } from 'react-icons/fa';
import gem from "../../img/gem.png"
import rias from "../../img/RiasImage.png";
import tracking from '../../tracking';
import oniichatnew from "../../img/Oniichat-NewLogo.svg";
const { Title } = Typography;
const SignInOut = ({ isVisible, onClose, redirectAfterAuth="selectChat", initialForm , images, displayType}) => {
  const [visible, setVisible] = useState(isVisible);
  const [loading, setLoading] = useState(false);
  const [isRegister, setIsRegister] = useState(initialForm === 'register');
  const navigate = useNavigate();
  const [isAgreed, setIsAgreed] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [isForgotPasswordVisible, setIsForgotPasswordVisible] = useState(false);
  const {characterData} = useContext(CharacterContext)
  const provider = new GoogleAuthProvider();
  const { setShowSignUp } = useContext(ChatContext);
  // Handle isVisible prop changes
  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  const switchForm = () => {
    setIsRegister(!isRegister);
    setIsAgreed(false);
  };
  const renderCustomContent = () => {
    switch (displayType) {
      case 'images':
        return (
          <div className={styles.ctaContainer}>
            <div className={styles.ctaTitle}>Unlock More Features!</div>
            <div className={styles.ctaText}>
              Enhance your journey with us! Register now to unlock new features, including exclusive waifu pictures!
            </div>
          </div>
        );
      case 'features':
        return (
          <div className={styles.ctaContainer}>
            <div className={styles.ctaTitle}>Unlock More Features!</div>
            <div className={styles.ctaText}>
              Enhance your journey with us! Register now to unlock new features, including personalized stories and a dedicated memory & diary function!
            </div>
          </div>
        );
      case 'locked':
        return (
          <div className={styles.ctaContainer}>
            <div className={styles.ctaTitle}>This Waifu Is Locked!</div>
            <div className={styles.ctaText}>
              Please register/log in to unlock the waifu.
            </div>
          </div>
        );
        case 'nsfw':
          return (
            <div className={styles.ctaContainer}>
              <div className={styles.ctaTitle}>This NSFW Message Is Locked!</div>
              <div className={styles.ctaText}>
                Please register/log in to unlock the full experience.
              </div>
            </div>
          );
          case 'voice':
            return (
              <div className={styles.ctaContainer}>
                <div className={styles.ctaTitle}>Exclusive Access to Premium Content Awaits!</div>
                <div className={styles.ctaText}>
                  Discover the full, unfiltered experience with our NSFW voice memos. Register or log in now to unlock a world of exclusive content tailored just for you.
                </div>
              </div>
            );
            case 'imageGen':
              return (
                <div className={styles.ctaContainer}>
                  <div className={styles.ctaTitle}>You have hit your image generation limit!</div>
                  <div className={styles.ctaText}>
                    Looks like you have hit a limit! Don't worry, join as a member today and receive a
                    <span className={styles.ctaBonus}> 100-gem sign-up bonus!</span>
                    <img src={gem} className={styles.iconBonus} /> 
                  </div>
                </div>
              );
      default:
        return (
          <div className={styles.ctaContainer}>
            <div className={styles.ctaTitle}>You have run out of gems!</div>
            <div className={styles.ctaText}>
              Looks like you have hit a limit! Don't worry, join as a member today and receive a
              <span className={styles.ctaBonus}> 100-gem sign-up bonus!</span>
              <img src={gem} className={styles.iconBonus} /> 
            </div>
          </div>
        );
    }
  };
  const fallbackTracking = {
    getTrackingParams: () => {
      return {
        utm_source: 'direct',
        utm_medium: 'none',
        utm_campaign: 'none',
        created_at: new Date().toISOString()
      };
    }
  };
  const retrieveChatsBeforeSignUp = async (tempSessionId) => {
    if (!tempSessionId) {
      console.error('No tempSessionId found in localStorage');
      return null;
    }
  
    try {
      const userDocRef = doc(db, "users", tempSessionId);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        console.error('Temporary user document does not exist');
        return null;
      }
  
      const chat_ids = userDoc.data().chats || [];
      const chats = [];
  
      for (const chatId of chat_ids) {
        const chatDocRef = doc(db, "chats", chatId);
        const chatDoc = await getDoc(chatDocRef);
  
        if (chatDoc.exists()) {
          chats.push({ id: chatId, data: chatDoc.data() });
        }
      }
  
      return chats;
    } catch (error) {
      console.error('Error retrieving chats:', error);
      return null;
    }
  };
  
  const takeOverChats = async (temporaryChats) => {
    console.log('Starting takeOverChats function');
    const tempSessionId = localStorage.getItem('tempSessionId');
    if (!tempSessionId) {
      console.error('No tempSessionId found in localStorage');
      return;
    }
  
    // Initialize a batch write to update all chats
    const batch = writeBatch(db);
  
    // Process each chat
    for (const { id, data } of temporaryChats) {
      const messages = data.messages || [];
      console.log(`Processing ${messages.length} messages for chat ID: ${id}`);
  
      // Update messages with the new user's UID
      const updatedMessages = messages.map(message => ({
        ...message,
        senderId: message.senderId === tempSessionId ? auth.currentUser.uid : message.senderId,
      }));
  
      // Create a new chat ID and new chat document
      const newChatId = createChatId(auth.currentUser.uid, id.replace(tempSessionId, ""));
      const newChatDocRef = doc(db, "chats", newChatId);
      batch.set(newChatDocRef, { messages: updatedMessages });
      console.log(`Chat ID ${id} processed and added to batch`);
    }
  
    // Commit the batch write
    await batch.commit();
    console.log('Completed processing chats in takeOverChats function');
  };
  const signInWithGoogle = async () => {
  setLoading(true);
  const batch = writeBatch(db);
  try {
    const tempSessionId = auth.currentUser.uid;
    const temporaryChats = await retrieveChatsBeforeSignUp(tempSessionId);
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    logEvent('sign_in_with_google');

    // Fetch existing data
    const existingUserDoc = await getDoc(doc(db, "users", tempSessionId));
    let { xp, maxXP, level } = existingUserDoc.exists() ? existingUserDoc.data() : { xp: 0, maxXP: 0, level: 0 };

    xp = xp != null ? xp : 0;
    maxXP = maxXP != null ? maxXP : 100;
    level = level != null ? level : 0;
    let trackingParams;
    try {
      trackingParams = tracking.getTrackingParams();
    } catch (error) {
      console.warn('Tracking blocked or failed, using fallback:', error);
      trackingParams = fallbackTracking.getTrackingParams();
    }
    const userDoc = await getDoc(doc(db, "users", user.uid));
    if (!userDoc.exists()) {
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        gems: 100,  
        ...trackingParams,
        subscription: "None",
        email: user.email,
        xp: xp,
        maxXP: maxXP,
        level: level
      });

      if(localStorage.getItem('tempSessionId')){
        await takeOverChats(temporaryChats);
        localStorage.removeItem('tempSessionId');
        localStorage.removeItem("messagesCount");
      }
    }

    message.success('Logged in successfully');
    setShowSignUp(false);
    onClose();
    setLoading(false);
  } catch (error) {
    logEvent('sign_in_with_google_failed', { error_message: error.message });
    message.error(AuthErrors[error.code] || error.message);
    setLoading(false);
  }
};
  

  const handleSendPasswordResetEmail = (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        message.success('Password reset email sent');
        setIsForgotPasswordVisible(false);
      })
      .catch((error) => {
        message.error('Error sending password reset email:', error);
      });
  }

  const openForgotPasswordModal = () => {
    setIsForgotPasswordVisible(true);
  }

  const handleOk = async (values) => {
    setLoading(true);
    const { email, password } = values;
  
    try {
      const batch = writeBatch(db);
  
      if (isRegister) {
        if (password.length < 6) {
          message.error('Password should be at least 6 characters');
          setLoading(false);
          return;
        }
        const tempSessionId = auth.currentUser.uid;
        const temporaryChats = await retrieveChatsBeforeSignUp(tempSessionId);
        localStorage.setItem('tempSessionId', auth.currentUser.uid);
        
        const existingUserDoc = await getDoc(doc(db, "users", tempSessionId));
        const { xp, maxXP, level } = existingUserDoc.exists() ? existingUserDoc.data() : { xp: 0, maxXP: 100, level: 1 };
  
        await createUserWithEmailAndPassword(auth, email, password);
        let trackingParams;
        try {
          trackingParams = tracking.getTrackingParams();
        } catch (error) {
          console.warn('Tracking blocked or failed, using fallback:', error);
          trackingParams = fallbackTracking.getTrackingParams();
        }
        const userDocRef = doc(db, "users", auth.currentUser.uid);
        batch.set(userDocRef, {
          uid: auth.currentUser.uid,
          gems: 100, 
          ...trackingParams,
          subscription: "None",
          email: email,
          xp: xp || 0,
          maxXP: maxXP || 100,
          level: level || 1
        });
  
        if(localStorage.getItem('tempSessionId')){
          await takeOverChats(temporaryChats);
          localStorage.removeItem('tempSessionId');
          localStorage.removeItem("messagesCount");
        }
        logEvent('register_successful');
        message.success('Account created successfully');
        onClose();
        
        // Only navigate to multistepform for new registrations
        navigate("/multistepform?char=");
  
      } else {
        // Regular sign in flow
        await signInWithEmailAndPassword(auth, email, password);
  
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        if (!userDoc.exists()) {
          const userDocRef = doc(db, "users", auth.currentUser.uid);
          batch.set(userDocRef, {
            uid: auth.currentUser.uid,
            gems: 100,  
            ...tracking.getTrackingParams(),
            subscription: "None",
            email: email
          });
        }
  
        logEvent('login_successful');
        message.success('Logged in successfully');
        onClose();
      }
  
      await batch.commit();
  
    } catch (error) {
      logEvent(isRegister ? 'register_failed' : 'login_failed', { error_message: error.message });
      message.error(AuthErrors[error.code] || error.message);
    }
  
    setLoading(false);
  };

  const handleAgreement = () => {
    setIsAgreed(!isAgreed);
  };


 return (
<Modal
  visible={visible}
  onCancel={onClose}
  footer={null}
  width="100%"
  className="p-0 [&_.ant-modal-content]:bg-[#2B3990] [&_.ant-modal-content]:p-0 [&_.ant-modal-content]:rounded-[32px] max-w-[320px] mx-auto md:max-w-[1000px]"
  closable={true}
  closeIcon={
    <span className="text-white text-2xl absolute right-3 top-3 z-50 hover:bg-white/10 rounded-full w-8 h-8 flex items-center justify-center cursor-pointer">
      ×
    </span>
  }
>
  <div className="flex flex-col md:flex-row min-h-[520px] md:min-h-[700px] rounded-[32px] overflow-hidden">
    {/* Image Section - Responsive for both mobile and desktop */}
    <div className="relative h-32 md:h-auto md:w-1/2 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-[#2B3990]/10 via-[#2B3990]/50 to-[#2B3990] z-10" />
      <img
        src={rias}
        alt="Character"
        className="absolute inset-0 w-full h-full object-cover object-[center_20%]"
      />
      <div className="absolute bottom-2 md:bottom-12 left-3 md:left-12 z-20 space-y-1 md:space-y-4">
        <img src={oniichatnew} alt="Logo" className="w-24 md:w-48 rounded-[22px]" />
        <p className="text-white/90 text-sm md:text-xl hidden md:block">Your Ultimate AI Companion</p>
      </div>
    </div>

    {/* Form Section */}
    <div className="w-full md:w-1/2 bg-[#2B3990] p-4 md:p-16 rounded-b-[32px] md:rounded-r-[32px] md:rounded-bl-none">
      <div className="max-w-[280px] mx-auto space-y-3 md:space-y-8">
        <div className="space-y-1 md:space-y-4">
          <h2 className="text-xl md:text-[42px] font-bold text-white leading-tight">
            {isRegister ? 'Create Account' : 'Welcome Back'}
          </h2>
          <p className="text-sm md:text-xl text-white/80">
            {isRegister ? 'Join our community' : 'Sign in to continue'}
          </p>
        </div>

        <button
          onClick={signInWithGoogle}
          className="w-full flex items-center justify-center space-x-2 bg-white rounded-lg md:rounded-[14px] py-2 md:py-4 px-4 md:px-6 transition-all duration-200 hover:bg-gray-50"
        >
          <FaGoogle className="text-[#2B3990]" />
          <span className="text-[#2B3990] font-medium text-sm md:text-base">Continue with Google</span>
        </button>

        <div className="relative py-2 md:py-4">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-white/10"></div>
          </div>
          <div className="relative flex justify-center text-xs md:text-lg">
            <span className="px-2 md:px-6 bg-[#2B3990] text-white/60">or continue with email</span>
          </div>
        </div>

        <Form onFinish={handleOk} layout="vertical" className="space-y-3 md:space-y-6">
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Invalid email format!' }
            ]}
          >
            <Input
              placeholder="Email address"
              className="h-10 md:h-14 border-2 border-white/20 text-sm md:text-lg rounded-lg md:rounded-xl px-4 md:px-6 
              input-placeholder-white
              transition-all duration-300 
              focus:shadow-[0_0_15px_rgba(255,255,255,0.1)] 
              focus:outline-none focus:ring-0 
              selection:bg-white/20
              [&_input]::selection:bg-white/20
              [&_input]:placeholder:text-white/40"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              placeholder="Password"
              className="h-10 md:h-14 border-2 border-white/20 text-sm md:text-lg rounded-lg md:rounded-xl px-4 md:px-6 
              input-placeholder-white
              transition-all duration-300 
              focus:shadow-[0_0_15px_rgba(255,255,255,0.1)] 
              focus:outline-none focus:ring-0 
              selection:bg-white/20
              [&_input]::selection:bg-white/20
              [&_input]:placeholder:text-white/40
              [&_.ant-input]:bg-transparent [&_.ant-input]:text-white
              [&_.anticon]:text-white/60
              [&_.ant-input-password-icon]:bg-transparent"
            />
          </Form.Item>

          {isRegister && (
            <Form.Item className="mb-2">
              <Checkbox
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
                className="text-white/80"
              >
                <span className="text-xs md:text-base text-white/80 hover:text-white">
                  I agree to the terms of use and privacy policy
                </span>
              </Checkbox>
            </Form.Item>
          )}

<Button
  type="primary"
  htmlType="submit"
  loading={loading}
  disabled={isRegister && !isAgreed}
  className="w-full h-10 md:h-14 bg-white/90 hover:bg-white text-[#2B3990] rounded-lg md:rounded-[14px] font-semibold text-sm md:text-lg transition-all duration-200"
>
  {isRegister ? 'Create Free Account' : 'Sign In'}
</Button>

        </Form>

        <div className="text-center text-xs md:text-lg text-white/60">
          <span>{isRegister ? 'Already have an account?' : "Don't have an account?"} </span>
          <button
            onClick={switchForm}
            className="relative inline-block text-white/80 hover:text-white font-medium bg-[#2B3990] hover:bg-[#344399] border-2 border-white/10 hover:border-white/30 transition-all duration-300 hover:shadow-[0_0_15px_rgba(255,255,255,0.1)] transform hover:-translate-y-0.5 px-2 py-0.5 rounded-lg text-xs md:text-base"
          >
            {isRegister ? 'Sign In' : 'Create Account'}
          </button>
        </div>

        {!isRegister && (
          <div className="text-center">
            <button
              onClick={() => setIsForgotPasswordVisible(true)}
              className="relative inline-block px-3 py-1.5 text-white/80 hover:text-white font-medium rounded-lg bg-[#2B3990] hover:bg-[#344399] border-2 border-white/10 hover:border-white/30 transition-all duration-300 hover:shadow-[0_0_15px_rgba(255,255,255,0.1)] transform hover:-translate-y-0.5 text-xs md:text-base"
            >
              Forgot your password?
            </button>
          </div>
        )}
      </div>
        </div>
      </div>

{/* Forgot Password Modal */}
<Modal
  visible={isForgotPasswordVisible}
  onCancel={() => setIsForgotPasswordVisible(false)}
  footer={null}
  className="rounded-[32px] overflow-hidden [&_.ant-modal-content]:bg-[#2B3990] [&_.ant-modal-content]:p-0 [&_.ant-modal-content]:rounded-[32px]"
>
  <div className="bg-[#2B3990] p-6 md:p-8 rounded-[32px]">
    <h3 className="text-xl md:text-2xl font-bold text-white mb-6">Reset Password</h3>
    <Form onFinish={() => handleSendPasswordResetEmail(forgotPasswordEmail)}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Invalid email format!' }
              ]}
            >
              <Input
                placeholder="Enter your email"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                className="h-12 md:h-14 border-2 border-white/20 text-base md:text-lg rounded-xl px-6 
                input-placeholder-white
                transition-all duration-300 
                focus:shadow-[0_0_15px_rgba(255,255,255,0.1)] 
                focus:outline-none focus:ring-0 
                selection:bg-white/20
                [&_input]::selection:bg-white/20
                [&_input]:placeholder:text-white/40"
              />
            </Form.Item>
            {/* Forgot Password Modal Button */}
<Button
  type="primary"
  htmlType="submit"
  className="w-full h-12 bg-white hover:bg-white/90 text-[#2B3990] rounded-xl font-semibold text-base transition-all duration-300 transform hover:scale-[1.02] shadow-lg hover:shadow-xl relative overflow-hidden
    before:absolute before:inset-0 before:bg-gradient-to-r before:from-white/0 before:via-white/30 before:to-white/0 before:translate-x-[-200%] hover:before:translate-x-[200%] before:transition-transform before:duration-1000"
>
  <span className="relative z-10 flex items-center justify-center space-x-2">
    <span>Send Reset Link</span>
    <span className="text-xl">→</span>
  </span>
</Button>
          </Form>
        </div>
      </Modal>
    </Modal>
  );
};

export default SignInOut;