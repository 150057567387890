// src/pages/Chat/FilterableContentPage.jsx

import React, { useState, useContext, useEffect, useRef, useCallback, memo } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Modal, Button, Spin, message } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  setDoc, 
  doc, 
  updateDoc, 
  arrayUnion, 
  deleteDoc, 
  getDoc,         
  writeBatch     
} from "firebase/firestore";

import bubbles from "../../img/bubbles.svg";
import gem from "../../img/gem.png";
import lock from "../../img/lock.png";

import MainNav from '../../components/Common/MainNav';
import FilterBar from '../../components/FilterBar';
import UnlockedCharacters from '../../components/UnlockedCharacters';
import UnlockedFeature from '../../components/UnlockedFeature';
import NoGemsModal from '../../components/NoGemsModal';
import DailyRewardsModal from '../../components/Chat/DailyRewards';
import SignInOut from '../../components/Auth/SignInOut';
import EarnWithOniichat from '../../components/EarnWithOniichat';

import { AuthContext } from "../../context/AuthContext";
import { ChatContext } from "../../context/ChatContext";
import { CharacterContext } from '../../context/CharacterContext';

import { db, logGemPurchase, auth, logEvent } from "../../firebase";
import { createChatId } from '../../helpers';
import tracking from '../../tracking';

import Rias from "../../img/FilterableBanner/Rias1.svg";
import Rias2 from "../../img/FilterableBanner/Rias1.svg";
import Nezuko from "../../img/FilterableBanner/Nezuko1.svg";
import Kushina from "../../img/FilterableBanner/Kushina.svg";

import "../../styles/characterCard.scss";
import "../../styles/unlockCharacterModal.scss";
// Define the mapping between preferences and categories outside the component
// Define the mapping between preferences and categories outside the component
const preferenceCategoryMap = {
  "male": ["male", "femboy"],
  "female": ["picture waifus", "furry", "romance", "anime game", "yandere", "private video waifus", "cute", "virgin", "realistic", "vtuber", "girlfriend", "love"],
  "furry": ["furry", "giantess", "dark fantasy", "pony"],
  "trans": ["special", "femboy"],
  "femboy": ["femboy", "special", "furry"],
};



const FilterableContentPage = () => {
  // State declarations
  const [showReward, setShowReward] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState('recommended');
  const [selectedCharacter, setSelectedCharacter] = useState(null);

  const [userPreferences, setUserPreferences] = useState([]);
  const [initialForm, setInitialForm] = useState("register");
  const { currentUser, userData, noUser } = useContext(AuthContext);
  const isSubscribed = currentUser && userData && userData.subscription && userData.subscription !== 'None';
  const { displayType, setDisplayType, dispatch, data, characterData } = useContext(ChatContext);
  const { charactersData, charactersLoading } = useContext(CharacterContext);
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showBuyCharacterModal, setShowBuyCharacterModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lockAmount, setLockAmount] = useState(true);
  const [UID, setUID] = useState("");
  const [postLink, setPostLink] = useState("");
  const [hasNewMessageSent, setHasNewMessageSent] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);
  const [favorites, setFavorites] = useState([]);
  /*const [favorites, setFavorites] = useState(userData && userData.favorites ? userData.favorites : []);*/
  const [visibility, setVisibility] = useState(false);
  const [filteredContentState, setFilteredContentState] = useState([]);

  const hasNewMessageSentRef = useRef(false);
  const loadingChatRef = useRef(false);

  const navigate = useNavigate();
  const location = useLocation();

  // Toggle visibility (unused in current code but kept for potential future use)
  const toggleVisibility = () => {
    setVisibility(!visibility);
  };

  // Fetch user preferences from Firestore
  const fetchUserPreferences = async () => {
    try {
      const userDoc = await getDoc(doc(db, "users", currentUser.uid));
      if (userDoc.exists()) {
        setUserPreferences(userDoc.data().FormData.preferences || []);
      }
    } catch (error) {
      console.error("Error fetching user preferences:", error);
    }
  };

  // Define handleLocked and handleLockedWithoutLogin functions
  const handleLocked = async (amount, uid, image) => {
    setUID(uid);
    setLockAmount(amount);
    setSelectedCharacter(image);
    setShowBuyCharacterModal(true);
  };

  const handleLockedWithoutLogin = () => {
    setDisplayType("locked");
    setShowSignUp(true);
  };

  // Fetch and set user preferences upon user authentication
  useEffect(() => {
    if (currentUser && !currentUser.isAnonymous) {
      fetchUserPreferences();
    }
  }, [currentUser]);
  
  useEffect(() => {
    if (userData) {
      setFavorites(userData.favorites || []);
    }
  }, [userData]);

  // Get selected category from URL or default to 'preferences'
  const getSelectedCategoryFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('category') || 'preferences';
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const categoryFromURL = searchParams.get('category');
  
    let newCategory;
    if (currentUser && !currentUser.isAnonymous) {
      newCategory = categoryFromURL || 'recommended';
    } else {
      newCategory = categoryFromURL || 'recommended';
    }
  
    if (selectedCategory !== newCategory) {
      setSelectedCategory(newCategory);
    }
  }, [currentUser]);

  // Function to update Firestore with anonymous user data
  const updateFirestore = async (tempSessionId) => {
    try {
      await setDoc(doc(db, "users", tempSessionId), {
        uid: tempSessionId,
        gems: 20,
        subscription: "None",
        FormData: { name: "Oniichan" },
        memories: []
      }, { merge: true });
    } catch (error) {
      console.error("Error updating Firestore:", error);
    }
  };

  const isSigningIn = useRef(false);

  // Handle authentication state changes
  useEffect(() => {
    const unsubscribeFromAuthState = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Logic for authenticated user (if any)
      } else {
        // Logic for unauthenticated user: sign in anonymously
        if (!isSigningIn.current) {
          isSigningIn.current = true;
          try {
            const userCredential = await signInAnonymously(auth);
            const user = userCredential.user;
            console.log('Anonymous Firebase user created successfully:', user.uid);
            localStorage.setItem('tempSessionId', user.uid);
            await updateFirestore(user.uid);
          } catch (error) {
            console.error('Error creating anonymous Firebase user:', error);
          } finally {
            isSigningIn.current = false;
          }
        }
      }
    });

    // Initialize user data if not present
    if (userData && userData.gems === undefined && userData.uid) {
      setDoc(doc(db, "users", currentUser.uid), {
        uid: currentUser.uid,
        gems: 100, 
        ...tracking.getTrackingParams(),
        subscription: "None",
        email: currentUser.email,
        xp: 0,
        maxXP: 100, 
        level: 1  
      }).catch(error => console.error("Error setting document:", error));
    }

    return () => {
      unsubscribeFromAuthState(); // Cleanup on unmount
    };
  }, [currentUser, noUser, userData]);

  // Function to calculate the difference in days
  const DayDifference = (date1, date2) => {
    const differenceInMilliseconds = Math.abs(date1 - date2);
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return differenceInDays;
  };

  // Function to format remaining time
  const formatTimeRemaining = (date) => {
    const remainingTime = (new Date(date.getTime() + 24 * 60 * 60 * 1000)) - new Date();
    const remainingHours = Math.floor(remainingTime / (60 * 60 * 1000));
    const remainingMinutes = Math.floor((remainingTime - remainingHours * 60 * 60 * 1000) / (60 * 1000));
    return `${padZero(remainingHours)}:${padZero(remainingMinutes)} h`;
  };

  // Helper function to pad numbers with leading zeros
  const padZero = (number) => {
    return number.toString().padStart(2, '0');
  };

  // Fetch progress (e.g., post links) for the user
  useEffect(() => {
    if (currentUser && !currentUser.isAnonymous && userData && !userData?.FormData) {
      navigate(`/multistepform`);
    }

    const loadProgress = async () => {
      if (currentUser) {
        try {
          const postLinkDoc = await getDoc(doc(db, 'quests', 'TwitterQuests'));
          if (postLinkDoc.exists()) {
            setPostLink(postLinkDoc.data().postLink);
            console.log("link1:", userData.lastClaimedPost, "link2:", postLinkDoc.data().postLink);
          }
        } catch (error) {
          console.error("Error loading progress:", error);
        }
      }
    };

    if (userData?.uid) {
      loadProgress();
    }
  }, [currentUser, userData, navigate]);

  // Memoize filtered content based on user preferences and selected category
  const filteredContent = React.useMemo(() => {
    const maxTotalCharacters = 500;
    let content = [];
  
    if (!charactersData) return [];
  
    // Sort characters based on message count
    const sortedCharacters = [...charactersData].sort((a, b) =>
      b.messagesCount - a.messagesCount
    );
  
    // Implement priority queue (heap) operations
    const heap = {
      values: sortedCharacters,
      remove: function () {
        return this.values.shift();
      },
      isEmpty: function () {
        return this.values.length === 0;
      },
    };
  
    // Fill up the content with characters based on the selected category or mapped categories
    while (!heap.isEmpty() && content.length < maxTotalCharacters) {
      const character = heap.remove();
  
      // Safeguard: Check if character.category exists
      if (!character.category) {
        console.warn(`Character ${character.uid} does not have a 'category' field.`);
        continue; // Skip this character
      }
  
      // Process the 'category' field based on its type
      let characterCategories = [];
      if (typeof character.category === 'string') {
        // Split the comma-separated string into an array
        characterCategories = character.category.split(',').map((cat) => cat.trim().toLowerCase());
      } else if (Array.isArray(character.category)) {
        // If already an array, map to lowercase and trim
        characterCategories = character.category.map((cat) => cat.trim().toLowerCase());
      } else {
        console.warn(`Character ${character.uid} has an unsupported 'category' type:`, typeof character.category);
        continue; // Skip this character
      }
  
      if (selectedCategory === 'favorites') {
        // Filter characters to include only those in the favorites array
        if (favorites.includes(character.uid)) {
          content.push(character);
        }
      } else if (selectedCategory === 'preferences' && userPreferences.length > 0) {
        // Filter based on mapped categories for the 'preferences' category
        const allPreferencesMatch = userPreferences.every((pref) => {
          const lowerPref = pref.toLowerCase();
          const mappedCats = preferenceCategoryMap[lowerPref] || [];
          return mappedCats.some((cat) => characterCategories.includes(cat));
        });
        if (allPreferencesMatch) {
          content.push(character);
        }
      } else if (
        selectedCategory &&
        (selectedCategory === 'all' ||
          characterCategories.some(
            (category) => category === selectedCategory.toLowerCase().trim()
          ))
      ) {
        content.push(character);
      }
    }
  
    return content;
  }, [charactersData, selectedCategory, favorites, currentUser, userPreferences]);
  
  

  // Update the filtered content state whenever filteredContent changes
  useEffect(() => {
    setFilteredContentState(filteredContent);
    console.log("filteredContent", filteredContent);
  }, [filteredContent]);

  // Handle category selection from the FilterBar
  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    logEvent('Search'); // Log the "Search" event when a category is selected
  };

  // Function to get a random character (unused in current code)
  const getRandomCharacter = (userData) => {
    const chatIds = userData?.chats;
    console.log("chat_ids", chatIds)
    if (!chatIds || chatIds.length === 0) return null;
  
    const randomIndex = Math.floor(Math.random() * chatIds.length);
    let chatId = chatIds[randomIndex];
  
    // The character's uid is the part of the chatId that isn't the user's uid
    const characterUid = chatId.replace(userData?.uid, '');
    chatId = currentUser.uid > characterUid ? currentUser.uid + characterUid : characterUid + currentUser.uid
    return { characterUid, chatId };
  };

  // Function to handle favorite toggling
  const onToggleFavorite = async (characterId) => {
    const userRef = doc(db, 'users', userData.uid); // Reference to the user's document in Firestore
    try {
      const currentFavorites = userData.favorites || [];
      const isFavorite = currentFavorites.includes(characterId);
      const updatedFavorites = isFavorite 
        ? currentFavorites.filter(id => id !== characterId) 
        : [...currentFavorites, characterId];

      await updateDoc(userRef, {
        favorites: updatedFavorites
      });
      setFavorites(updatedFavorites);
      // Optionally, you can dispatch an action or update context here
    } catch (error) {
      console.error("Error updating favorites:", error);
    }
  };

  // Memoized CharacterCard component to optimize rendering
  const CharacterCard = memo(({
    name, image, description, messagesCount, custom, locked = false, lockAmount, uid, newMessages, isNew, characterLevel, withPictures, withVideos = false, isFavorite
  }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
      const updateScreenSize = () => setScreenWidth(window.innerWidth);
      window.addEventListener('resize', updateScreenSize);
      return () => window.removeEventListener('resize', updateScreenSize);
    }, []);

    const isLocked = locked && characterLevel > (userData.level || 1);

    const getImageUrl = () => {
      return screenWidth > 768 ? image.replace('verySmall', 'small') : image;
    };

    const imageUrl = React.useMemo(() => getImageUrl(), [screenWidth, image]);

    return (
      <div className={`swiper-slide ${locked ? 'locked' : ''}`} 
       onClick={() => handleClick(isLocked, lockAmount, uid, withPictures, imageUrl)}> 
        <div className={`character-slide-card ${withPictures ? 'with-picture' : ''}`}>
          <button
            className={`favorite-button ${isFavorite ? 'is-favorite' : ''}`} // Apply 'is-favorite' class if the item is favorited
            onClick={(event) => {
              event.stopPropagation(); // Prevent the click from bubbling up to parent elements
              onToggleFavorite(uid); // Toggle the favorite state
            }}
          >
            <FontAwesomeIcon icon={faStar} /> 
          </button>
          {withPictures && <div className={`${withVideos ? 'video-badge' : 'picture-badge'}`}>{withVideos ? ("WITH VIDEOS") :("WITH PICTURES")}</div>}
          {isLocked ? (
            <div className="locked-overlay">
              <p className="unlock-requirement">Reach level {characterLevel} to unlock</p>
              <img
                onLoad={() => setImageLoaded(true)}
                className={`charater-avatar-image-locked ${imageLoaded ? 'visible' : ''}`}
                src={imageUrl}
                alt={name}
              />
              <div className="card-info">{name}</div>
              <div className="character-slide-info">{description}</div>
              <button className="unlock-button">
                Don't wait <span className="gems-amount">{lockAmount}<img className="textgem" src={gem} alt=""></img></span>
              </button>
            </div>
          ) : (
            <>
              {isNew && <div className="new-badge">NEW</div>}
              <img
                onLoad={() => setImageLoaded(true)}
                className={`charater-avatar-image ${imageLoaded ? 'visible' : ''}`}
                src={imageUrl}
                alt={name}
              />
              <div className="card-info">{name}</div>
              <div className="character-slide-info">{description ? description : "custom"}</div>
              {/* Edit and Delete Buttons (Commented Out) */}
              {/*<button onClick={() => onEditDescription(uid)}>Edit Description</button>
              <button onClick={() => onDeleteCharacter(uid)}>Delete</button>*/}
              {newMessages > 0 && <div className='newMessageIndicator'>{newMessages}</div>}
              {!custom && (
                <div className='card-footer'>
                  <img src={bubbles} alt="" />{messagesCount}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  });

  // Function to edit character description
  const onEditDescription = async (uid) => {
    const newDescription = prompt("Enter the new description:");
    if (newDescription) {
      const characterDocRef = doc(db, "newCharacters", uid);
      await updateDoc(characterDocRef, {
        description: newDescription
      }).catch(error => console.error("Error updating description:", error));
    }
  };

  // Function to delete a character
  const onDeleteCharacter = async (uid) => {
    if (window.confirm("Are you sure you want to delete this character?")) {
      const characterDocRef = doc(db, "newCharacters", uid);
      await deleteDoc(characterDocRef)
        .then(() => console.log("Character deleted"))
        .catch(error => console.error("Error deleting character:", error));
    }
  };

  // Function to handle unlocking a character
  const handleUnlock = async (uid) => {
    console.log("handle Unlock");

    if (!userData || (userData.gems - lockAmount < 0)) {
      setShowBuyCharacterModal(false);
      setShowWarning(true);
      return;
    }

    await updateDoc(doc(db, "users", currentUser.uid), {
      gems: userData.gems - lockAmount
    }, { merge: true });
    
    logGemPurchase(currentUser.uid, lockAmount, "unlock character", uid);
    setShowBuyCharacterModal(false);

    // Update charactersLocked in userData
    const charactersLocked = [...userData.charactersLocked];
    const index = charactersLocked.findIndex(character => character.uid === uid);

    console.log("index found", index, "for", uid);
    if (index !== -1) {
      charactersLocked[index] = { ...charactersLocked[index], locked: false };
      await updateDoc(doc(db, 'users', currentUser.uid), { charactersLocked });

      // Update the filteredContentState to reflect the unlocked character
      const updatedFilteredContent = filteredContentState.map(character => {
        if (character.uid === uid) {
          console.log("character found", uid, { ...character, locked: false });
          return { ...character, locked: false };
        }
        return character;
      });

      setFilteredContentState(updatedFilteredContent);
    }
  };

  // Function to start a chat with a character
  const StartChat = async (characterUID, withPictures) => {
    loadingChatRef.current = true;
    setLoadingChat(true);
    // Fetch sessionId; it will be either currentUser's UID or tempSessionId
    console.log(localStorage.getItem('tempSessionId'))
    const sessionId = currentUser.uid;
    console.log("start chat session id", sessionId)
    // Initialize the Firestore batch
    const batch = writeBatch(db);
    
    // Only log analytics if a currentUser exists
    if (currentUser) {
      logEvent(`clicked_character_${characterUID}`);
      window.trackExoClickConversion('openChat');
      window.trackMetaConversion('openChat');
      window.trackMetaConversion('ViewContent');
      window.trackTwitterConversion("openChat");
      logEvent('viewContent'); // Log the "ViewContent" event
    }
    const collectionName = withPictures ? "characters" : "newCharacters";
    try {
      // Fetch character from Firestore
      let character = null;
      const q = query(
        collection(db, collectionName),
        where("uid", "==", characterUID)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        character = doc.data();
      });
    
      if (!character) {
        throw new Error("Character not found");
      }
    
      // Create or update the chat group
      console.log("character id", character, "user id ", currentUser);
      const combinedId = createChatId(currentUser.uid, character.uid);
      const userDocRef = doc(db, "users", sessionId);
      if (!userData?.chats?.includes(combinedId)) {
        batch.update(userDocRef, {
          chats: arrayUnion(combinedId)
        });
      }
      const chatDocRef = doc(db, "chats", combinedId);
      const chatDocSnapshot = await getDoc(chatDocRef);
      if (!chatDocSnapshot.exists() || chatDocSnapshot.data().messages === null) {
        batch.set(chatDocRef, { messages: [], id: combinedId});
      }
  
      // Commit the batch
      await batch.commit();
  
      console.log("Dispatch the character to the Redux store and navigate to the chat page");
      dispatch({ type: "CHANGE_USER", payload: character });
      setTimeout(() => {
        console.log("navigating to chat");
        navigate(`/chat?char=${character.uid}` + (withPictures ? "" : "&new=true"));
        setLoadingChat(false); // Set loadingChat to false after navigation
      }, 0); // You can adjust the timeout duration as needed
    } catch (error) {
      console.error(error);
      setLoadingChat(false); // Ensure loading state is reset in case of error
    }
  };

  // Handle character card clicks
  const handleClick = useCallback((locked, lockAmount, uid, withPictures, image) => {
    console.log("Session ID:", currentUser.uid);
    console.log("Locked:", locked);
    console.log("Image URL:", image); // Add this log to debug
    logEvent("first character click");
    if (locked) {
      console.log("Character is locked:", locked);
      if (!currentUser.isAnonymous) {
        handleLocked(lockAmount, uid, image);
      } else {
        handleLockedWithoutLogin();
      }
    } else {
      console.log("Starting chat");
      StartChat(uid, withPictures);
    }
  }, [handleLocked, StartChat, currentUser]);

  return (
    <>
      <div>
        {loadingChat ? (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : (
          <div className='mainWrapper'>
            <MainNav/>
            {charactersLoading || (characterData && characterData.length < 10) || !currentUser ? (
              <div style={{ justifyContent: "center", display: "flex", marginTop: "3rem" }}>
                <div className="loading-message">
                  <h3>Loading Characters</h3>
                  <p>Just a moment, please wait...</p>
                  <div className="loading-progress">
                    <div className="loading-bar"></div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='selectChat'>
                <div className="content">
                  <div className="banner">
                    <i className="banner-icon fab fa-discord"></i>
                    <a 
                      href="https://discord.gg/Se96PwTVm5" 
                      className="banner-link" 
                      target="_blank" 
                      rel="noreferrer"
                    >
                      Join our Discord community!
                    </a>
                  </div>
                  {!currentUser.isAnonymous && (
                    <div className='dailyRewardTimer'>
                      {userData?.lastClaimed ? 
                        (DayDifference(userData.lastClaimed.toDate(), new Date()) > 0.99 ? 
                          (<div className="dailyRewardButton" onClick={() => setShowReward(true)}>Claim Reward</div>) : 
                          (<div>Next Daily Reward in {formatTimeRemaining(userData.lastClaimed.toDate())}</div>)
                        ) : 
                        (<div></div>)
                      }
                      <div className="dailyRewardButton" onClick={() => setShowReward(true)}>
                        {userData?.lastClaimedPost && postLink ? 
                          (userData.lastClaimedPost === postLink +"?trackId=75767fg7t8z8h" ? 
                            ("Earn Gems") : ("New quest available")) : 
                          ("Earn Gems")
                        }
                      </div>
                    </div>
                  )}

                  {!isSubscribed && currentUser && !currentUser.isAnonymous && (
                    <>
                  <div className='purchase-txt' onClick={() => navigate('/shop')} style={{ cursor: 'pointer' }}>
                    <div className='inner-wrapper'>
                    Up to <span className='off'>70%</span>OFF
                    </div>
                    </div>
                    <div className='waifus-txt' onClick={() => navigate('/shop')} style={{ cursor: 'pointer' }}>
                    <div className='waifus-wrapper'>
                    Commit to <span className='woff'>your</span> Waifus
                    </div>
                    </div>
                  <div className='purchase ultra' onClick={() => navigate('/shop')} style={{ cursor: 'pointer' }} >
                    <img src={Nezuko} alt="Nezuko-Image" className='character-image nezuko'/>
                    <img src={Rias} alt="Rias-Image" className='character-image rias'/>
                    <img src={Kushina} alt="Kushina-Image" className='character-image kushina'/>
                  </div>
                  </>
                )}

                  <FilterBar selectedCategory={selectedCategory} handleSelectCategory={handleSelectCategory}/>
                  <span className='disclaimer'>All characters depicted are over the age of 18</span>
                  
                  {/* Conditional Rendering Based on Selected Category and Favorites */}
                  {selectedCategory.toLowerCase() === 'favorites' && filteredContentState.length === 0 ? (
                    <div className='favorites-container'>
                      <p className='NoWaifus'>No waifus here yet!</p>
                      <div className='favorites-info'>
                        <p>
                          Click on the Star Icon on the top right of a character to add them to your own favorites list!
                        </p>
                        {/* Embedded Video */}
                        <div className="favorites-video">
                        <video 
        width="100%" 
        height="auto" 
        autoPlay 
        loop 
        muted
        src="https://pub-45d257aada644f7c97773ae944d2ec4d.r2.dev/Kein%20Titel%20(100%20x%2059.4%20cm)%20(650%20x%20100%20px)%20(100%20x%2059.4%20cm)%20(100%20x%2049.4%20cm)%20(60%20x%2049.4%20cm)%20(1024%20x%201536%20px)%20(1080%20x%201080%20px)%20(1014%20x%201080%20px)%20(1).mp4"
      >
        Your browser does not support the video tag.
      </video>
                        </div>
                      </div>
                    </div>
                  ) : (
                    filteredContentState.length === 0 ? (
                      <div className='NoWaifus'>No waifus here yet</div>
                    ) : (
                      <div className="grid">
                        {filteredContentState.map(({ name, image, description, category, messagesCount, locked, lockAmount, uid, newMessages, level, withPictures, withVideos }) => (
                          <CharacterCard
                            key={uid} // Changed key to 'uid' for uniqueness
                            name={name}
                            image={image}
                            locked={locked}
                            lockAmount={lockAmount}
                            custom={Array.isArray(category) ? category.includes("custom") : category.toLowerCase().includes("custom")}
                            messagesCount={messagesCount}
                            category={category}
                            description={description ? description : "custom"}
                            uid={uid}
                            newMessages={newMessages}
                            isNew={name === "Eva"}
                            characterLevel={level}
                            withPictures={withPictures}
                            withVideos={withVideos}
                            isFavorite={favorites.includes(uid)}
                          />
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Unlock Character Modal */}
      <Modal
        title="INFORMATION"
        visible={showBuyCharacterModal}
        onOk={() => handleUnlock(UID)}
        onCancel={() => setShowBuyCharacterModal(false)}
        className="unlock-character-modal"
        footer={[
          <Button key="back" onClick={() => setShowBuyCharacterModal(false)}>
            No
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleUnlock(UID)}>
            Yes
          </Button>,
        ]}
      >
        {selectedCharacter && (
    <div className="character-preview">
      <img src={selectedCharacter} alt="Character preview" />
    </div>
  )}
        <p>
          Would you like to unlock this character for {lockAmount}
          <img className="textgem" src={gem} alt="Gem Icon"></img>
        </p>
      </Modal>

      {/* Sign Up Modal */}
      <SignInOut 
        isVisible={showSignUp}
        onClose={() => setShowSignUp(false)}
        initialForm="register"
        displayType={displayType}
      />

      {/* No Gems Warning Modal */}
      <NoGemsModal
        visible={showWarning}
        onCancel={() => setShowWarning(false)}
      />

      {/* Daily Rewards Modal */}
      {currentUser && !currentUser.isAnonymous && (
        <DailyRewardsModal 
          show={showReward} 
          onClose={() => setShowReward(false)} 
          onShow={() => setShowReward(true)}
        />
      )}
    </>
  );
};

// Export the component as default
export default FilterableContentPage;